import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iLeasingRequest } from 'datamodel';

export const leasingRequestsSlice = createSlice({
  name: 'leasingRequests',
  initialState: [] as iLeasingRequest[],
  reducers: {
    setLeasingRequestsValue: (_, action: PayloadAction<iLeasingRequest[]>) => action.payload,
  },
});

export const { setLeasingRequestsValue } = leasingRequestsSlice.actions;

export default leasingRequestsSlice.reducer;
