import { firebaseConfig } from 'config/firebase';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

// import { getMessaging, getToken } from 'firebase/messaging';

import { overrideFirebaseEmulators } from 'config/defaults';
import { getPerformance } from 'firebase/performance';
// import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

const app = initializeApp(firebaseConfig());
initializeFirestore(app, { experimentalForceLongPolling: true });
const perf = getPerformance(app);
const db = getFirestore(app);
const storage = getStorage(app);

// db.settings({ experimentalForceLongPolling: true });
const auth = getAuth(app);
auth.languageCode = 'de';
const functions = getFunctions(app, 'europe-west3');
let an;
isSupported().then((supported) => {
  if (supported) {
    an = getAnalytics(app);
  }
});
const analytics = an;

// const remoteConfig = getRemoteConfig(app);
// remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
// console.log('Remote Config', remoteConfig);
// fetchAndActivate(remoteConfig).then((activated) => {
// console.log('Remote Config activated', activated);
// });
// const vapidKey = 'BJw5Rhv8PzA7HXzZa8dRs0tqL0yNeh72NBd0yUccdCPgyJX2MPQzPqAcGYzFqoyNHNvqq-zia03bqLfshhLH2IQ';
// const messaging = getMessaging(app);
// getToken(messaging, {
//   vapidKey,
// }).then((token) => {
//   if (token) {
//     console.log('token', token);
//   } else {
//     console.log('No Instance ID token available. Request permission to generate one.');
//   }
// });

// const devKey = '6Lenwt4eAAAAAOpyVig8dRGVxcgc-yxBzEQAf6nj';
// const prodKey = '6LdGx2YbAAAAAF5kt6EHnHiugghgR7ZMO7L4BDKb';
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(firebaseConfig().reCaptchaV3Key),
  isTokenAutoRefreshEnabled: true,
});
auth.useDeviceLanguage();
if (process.env.NODE_ENV === 'development' && !overrideFirebaseEmulators) {
  console.log('🚧 Node Environment: ', process.env.NODE_ENV);
  // dev code
  console.log("🚧 Connecting to local dev, run 'firebase emulators:start' in the backend project");
  console.log('🚧 Firestore Emulator connection, 8080');
  connectFirestoreEmulator(db, 'localhost', 8080);
  console.log('🚧 Authentication Emulator connection: 9099');
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  console.log('🚧 Functions Emulator connection: 9099');
  connectFunctionsEmulator(functions, 'localhost', 5001);
  console.log('🚧 Storage Emulator connection: 9199');
  connectStorageEmulator(storage, 'localhost', 9199);
  console.log('🚧 Emulator Suite UI: "http://localhost:4000"');
} else {
  // production code
}
export { analytics, appCheck, auth, db, functions, perf, storage };

export default app;
