/**
 * This is the user record from the company, here different values and settings can be stored
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iUser } from 'datamodel';

export const userSlice = createSlice({
  name: 'user',
  initialState: null as iUser | null,
  reducers: {
    setUserValue: (_, action: PayloadAction<iUser | null>) => action.payload,
  },
});

export const { setUserValue } = userSlice.actions;

export default userSlice.reducer;
