import { IsBooleanString, IsString } from 'class-validator';
import { eShiftPartOfDay } from '../../Enums';
import { iLeasingRequest } from '../../Interfaces/Leasing';
import { iOffer, iVolunteerOffer } from '../../Interfaces/Offers';
import { iPoolItem } from '../../Interfaces/PoolManagement';
import { iUser } from '../../Interfaces/Users';

export class DispoTableParamsDto {
  @IsString()
  startDate: string;
  @IsString()
  endDate: string;
  // @IsBoolean()
  @IsBooleanString()
  loadAm = false;
  // @IsBoolean()
  @IsBooleanString()
  loadPm = false;
  // @IsBoolean()
  @IsBooleanString()
  loadLeasing = false;
  // @IsBoolean()
  @IsBooleanString()
  loadJoker = false;
}

export class DispoTableResponse {
  message: string;
  range: string[];
  openItems: DispoTableDate[];
  result: DispoTableResult[];
  time: {
    message: string;
    duration: number;
  };
}

export class DispoTableResult {
  user: iUser;
  dates: DispoTableDate[];
  isPool: boolean;
}
export class DispoTableDate {
  whenString: string;
  offerItems: { slot: eShiftPartOfDay; item: iOffer }[];
  poolItems: { slot: eShiftPartOfDay; item: iPoolItem }[];
  leasingItems: { slot: eShiftPartOfDay; item: iLeasingRequest }[];
  volunteerItems: { slot: eShiftPartOfDay; item: iVolunteerOffer }[];
}
