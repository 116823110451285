import { checkUserId } from 'database';
import { ActiveFilterType, eOfferTypes, iUserRecord } from 'datamodel';
import { DocumentData, DocumentReference, deleteField, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from 'localFirebase';

export const getRootUserRef = (): DocumentReference<DocumentData> | false => {
  const userId = checkUserId();
  if (!userId) return false;
  const userRoot = doc(db, 'users', userId);
  return userRoot;
};

export const getRootUserDoc = async (): Promise<iUserRecord | false> => {
  const rootUserRef = getRootUserRef();
  if (!rootUserRef) return false;
  const userRootDoc = await getDoc(rootUserRef);
  if (userRootDoc.exists()) return { id: userRootDoc.id, ...userRootDoc.data() } as iUserRecord;
  return false;
};

export const getRootUserRefById = (id: string): DocumentReference<DocumentData> | false => {
  const userRoot = doc(db, 'users', id);
  return userRoot;
};

export const updateRootUser = async (value: Partial<iUserRecord>): Promise<void> => {
  const rootUserRef = getRootUserRef();
  if (!rootUserRef) return;
  const res = await updateDoc(rootUserRef, value);
  return res;
};

export const getRootUserDocById = async (id: string): Promise<iUserRecord | false> => {
  const rootUserRef = doc(db, 'users', id);
  const userRootDoc = await getDoc(rootUserRef);
  // Checks and rename of isLeasingCompany to isLeasing
  // This can be removed if there are no more rootUsers with isLeasingCompany.
  if (userRootDoc.exists()) {
    if (userRootDoc.data().isLeasingCompany) {
      await updateDoc(rootUserRef, { isLeasing: true, isLeasingCompany: deleteField() });
      return { id: userRootDoc.id, ...userRootDoc.data(), isLeasing: true } as iUserRecord;
    }
    return { id: userRootDoc.id, ...userRootDoc.data() } as iUserRecord;
  }
  return false;
};

export const updateRootUserById = async (id: string, value: Partial<iUserRecord>): Promise<void> => {
  const rootUserRef = getRootUserRefById(id);
  if (!rootUserRef) return;
  const res = await updateDoc(rootUserRef, value);
  return res;
};

export const updateRootUserSettings = async (
  name: string,
  value: null | string | boolean | eOfferTypes | undefined | ActiveFilterType[],
): Promise<void> => {
  const rootUserRef = getRootUserRef();
  if (!rootUserRef) return;
  const res = await updateDoc(rootUserRef, { [`settings.${name}`]: value === undefined ? false : value });
  return res;
};
