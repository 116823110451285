import { checkTenantId } from 'database';
import { iLeasingCompany } from 'datamodel';
import { DocumentData, DocumentReference, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from 'localFirebase';

const debug = false;

export const getCompanyRef = (): DocumentReference<DocumentData> | false => {
  if (debug) console.log('getCompanyRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const house = doc(db, 'leasingCompanies', tenantId);
  return house;
};

export const getCompanyDoc = async (): Promise<iLeasingCompany | false> => {
  if (debug) console.log('getCompanyDoc');
  const houseRef = getCompanyRef();
  if (!houseRef) return false;
  const houseDoc = await getDoc(houseRef);
  if (houseDoc.exists()) return { id: houseDoc.id, ...houseDoc.data() } as iLeasingCompany;
  return false;
};

export const updateLeasingCompany = async (company: Partial<iLeasingCompany>): Promise<void> => {
  const tenantId = checkTenantId();
  if (!tenantId) return;
  const companyRef = collection(db, 'leasingCompanies');
  await setDoc(doc(companyRef, tenantId), company, { merge: true });
};
