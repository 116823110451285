import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iLeasingCustomerShifts } from 'datamodel/src/Interfaces/Leasing';

export const leasingCustomerShiftsSlice = createSlice({
  name: 'leasingCustomerShifts',
  initialState: [] as iLeasingCustomerShifts[],
  reducers: {
    setLeasingCustomersShiftsValue: (_, action: PayloadAction<iLeasingCustomerShifts[]>) => action.payload,
  },
});

export const { setLeasingCustomersShiftsValue } = leasingCustomerShiftsSlice.actions;

export default leasingCustomerShiftsSlice.reducer;
