import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iLeasingEmployeeOffer } from 'datamodel';

export const LeasingUnresolvedOffersSlice = createSlice({
  name: 'LeasingUnresolvedOffers',
  initialState: [] as iLeasingEmployeeOffer[],
  reducers: {
    setLeasingUnresolvedOffersValue: (_, action: PayloadAction<iLeasingEmployeeOffer[]>) => action.payload,
  },
});

export const { setLeasingUnresolvedOffersValue } = LeasingUnresolvedOffersSlice.actions;

export default LeasingUnresolvedOffersSlice.reducer;
