import de from "./locales/de/translation.json";
import en from "./locales/en/translation.json";
// import pl from "./locales/pl/translation.json";

const translations = {
  de,
  en,
  // pl,
};

export default translations;
