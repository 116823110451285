import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iLeasingRequest } from 'datamodel';

export const LeasingUnresolvedRequestsSlice = createSlice({
  name: 'LeasingUnresolvedRequests',
  initialState: [] as iLeasingRequest[],
  reducers: {
    setLeasingUnresolvedRequestsValue: (_, action: PayloadAction<iLeasingRequest[]>) => action.payload,
  },
});

export const { setLeasingUnresolvedRequestsValue } = LeasingUnresolvedRequestsSlice.actions;

export default LeasingUnresolvedRequestsSlice.reducer;
