import { eShiftPartOfDay } from '../Enums';
import { iShift } from '../Interfaces/Shifts';

const debug = false;

/**
 *
 * @param shift iShift Object
 * @returns number of minutes the break is
 * @example getBreakMinutes(shift)
 */
export const getShiftBreakTime = (shift: iShift): number => {
  const startMinutes = shift.from.h * 60 + shift.from.m;
  let endMinutes = shift.till.h * 60 + shift.till.m;
  // Add a day if the shift ends the next day.
  if (endMinutes < startMinutes) endMinutes += 24 * 60;
  const duration = endMinutes - startMinutes;
  if (duration < 6 * 60) return 0;
  if (duration < 9 * 60 + 30) return 30;
  return 45;
};

/**
 *
 * @param shift iShift Object
 * @returns number of minutes the work day is
 * @example getWorkMinutes(shift)
 */
export const getShiftWorkTime = (shift: iShift) => {
  const breakTime = getShiftBreakTime(shift);
  const startMinutes = shift.from.h * 60 + shift.from.m;
  let endMinutes = shift.till.h * 60 + shift.till.m;
  // Add a day if the shift ends the next day.
  if (endMinutes < startMinutes) endMinutes += 24 * 60;
  const duration = endMinutes - startMinutes;
  if (debug) console.log('WorkTime is: ' + (duration - breakTime));
  return duration - breakTime;
};

export const translateShiftToFourth = (shift: iShift) => {
  if (shift.from.h >= 5 && shift.from.h <= 7) return 'e';
  if (shift.from.h >= 8 && shift.from.h <= 12) return 'm';
  if (shift.from.h >= 13 && shift.from.h <= 15) return 'l';
  return 'n';
};

export const translateShiftToFourthEnums = (shift: iShift) => {
  if (shift.from.h >= 5 && shift.from.h <= 7) return eShiftPartOfDay.Early;
  if (shift.from.h >= 8 && shift.from.h <= 12) return eShiftPartOfDay.Middle;
  if (shift.from.h >= 13 && shift.from.h <= 15) return eShiftPartOfDay.Late;
  return eShiftPartOfDay.Night;
};

export const translateFourthToShifts = (fourth: string, shifts: iShift[]) => {
  if (!shifts) return [];
  const matchingShifts = shifts.filter((s) => translateShiftToFourth(s) === fourth);
  return matchingShifts;
};
