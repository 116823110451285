import { Themes } from 'datamodel';
import store from 'rdx/store';
import { theme } from 'shared';
import { crewLinQTheme } from './branches/crewlinq';
import { leasingTheme } from './branches/leasing';
import { neutralTheme } from './branches/neutral';
import { defaultColors } from './defaultColors';

// eslint-disable-next-line import/prefer-default-export
export const getTheme = (lng: 'de' | 'en' = 'de') => {
  const { appState } = store.getState();
  if (appState.theme === Themes.leasing)
    return {
      theme: leasingTheme(lng),
      logo: '/assets/logos/logo_transparent_leasing.png',
      product: theme.products.leasing,
      colors: {
        ...defaultColors,
        primary: theme.colors.products.leasing.primary,
        secondary: theme.colors.products.leasing.secondary,
        accent: theme.colors.products.leasing.accent,
        accentTransparent: theme.colors.products.leasing.accentRgba(0.3),
        primaryTransparent: theme.colors.products.leasing.primaryRgba(0.3),
        primaryTransparentLight: theme.colors.products.leasing.primaryRgba(0.2),
        primaryDark: theme.colors.products.leasing.primaryDark,
        primaryLight: theme.colors.products.leasing.primaryLight,
        primaryText: theme.colors.products.leasing.secondaryFontColor,
        primaryTextSoft: theme.colors.products.leasing.secondaryFontColorRgba(0.8),
        primaryTextRgba: (opacity: number) => theme.colors.products.leasing.secondaryFontColorRgba(opacity),
        secondaryText: theme.colors.products.leasing.primaryFontColor,
        secondaryTextSoft: theme.colors.products.leasing.primaryFontColorRgba(0.8),
        secondaryTextRgba: (opacity: number) => theme.colors.products.leasing.primaryFontColorRgba(opacity),
        accentRgba: (opacity: number) => theme.colors.products.leasing.accentRgba(opacity),
        primaryRgba: (opacity: number) => theme.colors.products.leasing.primaryRgba(opacity),
        secondaryRgba: (opacity: number) => theme.colors.products.leasing.secondaryRgba(opacity),
      },
    };
  if (appState.theme === Themes.crewlinq)
    return {
      theme: crewLinQTheme(lng),
      logo: '/assets/logos/logo_transparent.png',
      product: theme.products.crewlinq,
      colors: {
        ...defaultColors,
        primary: theme.colors.products.crewlinq.primary,
        secondary: theme.colors.products.crewlinq.secondary,
        accent: theme.colors.products.crewlinq.accent,
        accentTransparent: theme.colors.products.crewlinq.accentRgba(0.3),
        primaryTransparent: theme.colors.products.crewlinq.primaryRgba(0.3),
        primaryTransparentLight: theme.colors.products.crewlinq.primaryRgba(0.2),
        primaryDark: theme.colors.products.crewlinq.primaryDark,
        primaryLight: theme.colors.products.crewlinq.primaryLight,
        primaryText: theme.colors.products.crewlinq.secondaryFontColor,
        primaryTextSoft: theme.colors.products.crewlinq.secondaryFontColorRgba(0.8),
        primaryTextRgba: (opacity: number) => theme.colors.products.crewlinq.secondaryFontColorRgba(opacity),
        secondaryText: theme.colors.products.crewlinq.primaryFontColor,
        secondaryTextSoft: theme.colors.products.crewlinq.primaryFontColorRgba(0.8),
        secondaryTextRgba: (opacity: number) => theme.colors.products.crewlinq.primaryFontColorRgba(opacity),
        accentRgba: (opacity: number) => theme.colors.products.crewlinq.accentRgba(opacity),
        primaryRgba: (opacity: number) => theme.colors.products.crewlinq.primaryRgba(opacity),
        secondaryRgba: (opacity: number) => theme.colors.products.crewlinq.secondaryRgba(opacity),
      },
    };
  return {
    theme: neutralTheme(lng),
    logo: '/assets/logos/crewlinq_logo.png',
    product: theme.products.neutral,
    colors: {
      ...defaultColors,
    },
  };
};
