import { checkTenantId } from 'database';
import { iLeasingAddressBookEntry } from 'datamodel';
import { DocumentData, Query, collection, doc, getDoc, orderBy, query, setDoc, where } from 'firebase/firestore';
import { db } from 'localFirebase';

export const getAddressBookEntry = async (): Promise<iLeasingAddressBookEntry | false> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const addressBookRef = collection(db, 'leasingCompanies', 'public', 'allCompanies');
  const addressBookDoc = await getDoc(doc(addressBookRef, tenantId));
  if (!addressBookDoc.exists()) return false;
  return { id: addressBookDoc.id, ...addressBookDoc.data() } as iLeasingAddressBookEntry;
};

export const updateAddressBookEntry = async (entry: Partial<iLeasingAddressBookEntry>): Promise<void> => {
  const tenantId = checkTenantId();
  if (!tenantId) return;
  const addressBookRef = collection(db, 'leasingCompanies', 'public', 'allCompanies');
  await setDoc(doc(addressBookRef, tenantId), entry, { merge: true });
};

export const getCrewLinQAddressBookEntires = (): Query<DocumentData> | false => {
  const addressBookRef = collection(db, 'customers', 'public', 'addressBook');
  const q = query(addressBookRef, where('public', '==', true), orderBy('name', 'asc'));
  return q;
};
