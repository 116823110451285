import '@fontsource/roboto';

import App from 'App';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'localFirebase';
import 'moment/locale/de';
import store from 'rdx/store';
import React, { Suspense } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ReactDOM from 'react-dom/client';
import 'react-json-pretty/themes/monikai.css';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'typeface-hk-grotesk';
import './index.css';

// if (process.env.NODE_ENV === 'production') {
//   console.log = () => {};
//   // console.error = () => {};
// }

if (analytics) logEvent(analytics, 'Page_Loaded');
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Suspense fallback="loading">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </ReduxProvider>
  </React.StrictMode>,
);
