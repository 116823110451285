import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { iNewsState } from 'datamodel';

const initialState: iNewsState[] = [];

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNewsValue: (state, action: PayloadAction<iNewsState[]>) => {
      return action.payload;
    },
    updateNewsValue: (state, action: PayloadAction<iNewsState>) => {
      const index = state.findIndex((d) => d.id === action.payload.id);
      if (index === -1) return state;
      const newState = [...state];
      newState[index] = action.payload;
      return newState;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setNewsValue, updateNewsValue, reset } = newsSlice.actions;

export default newsSlice.reducer;
