import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iQualification } from 'datamodel';

const initialState: iQualification[] = [];

export const qualificationsSlice = createSlice({
  name: 'qualifications',
  initialState,
  reducers: {
    setQualificationsValue: (state, action: PayloadAction<iQualification[]>) => action.payload,
  },
});

// Action creators are generated for each case reducer function
export const { setQualificationsValue } = qualificationsSlice.actions;

export default qualificationsSlice.reducer;
