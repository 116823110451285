import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iLeasingExternalCustomerOffer } from 'datamodel';

export const LeasingUnresolvedBookingsSlice = createSlice({
  name: 'LeasingUnresolvedBookings',
  initialState: [] as iLeasingExternalCustomerOffer[],
  reducers: {
    setLeasingUnresolvedBookingsValue: (_, action: PayloadAction<iLeasingExternalCustomerOffer[]>) => action.payload,
  },
});

export const { setLeasingUnresolvedBookingsValue } = LeasingUnresolvedBookingsSlice.actions;

export default LeasingUnresolvedBookingsSlice.reducer;
