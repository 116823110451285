import { checkCustomerId, checkTenantId, checkUserId } from 'database';
import { iNotification } from 'datamodel';
import { CollectionReference, DocumentData, Timestamp, addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from 'localFirebase';
import { getUserRef } from './user';

export const getNotificationsRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!customerId || !tenantId) return false;
  const notificationsRef = collection(db, 'customers', customerId, 'houses', tenantId, 'notifications');
  return notificationsRef;
};

export const getUserNotificationsRef = (): CollectionReference<DocumentData> | false => {
  const userRef = getUserRef();
  if (!userRef) return false;
  const notifications = collection(userRef, 'notifications');
  return notifications;
};

export const addNotification = async (notification: Partial<iNotification>): Promise<boolean> => {
  const userId = checkUserId();
  const tenantId = checkTenantId();
  const newNotification = {
    ...notification,
    createdAt: Timestamp.now(),
    forId: notification.personal ? userId : tenantId,
    read: false,
  };
  delete newNotification.id;
  if (notification.personal) {
    const userRef = getUserRef();
    if (!userRef) return false;
    const notificationsRef = collection(userRef, 'notifications');
    await addDoc(notificationsRef, newNotification);
    return true;
  }
  const notificationsRef = getNotificationsRef();
  if (!notificationsRef) return false;
  await addDoc(notificationsRef, newNotification);
  return true;
};

export const markNotificationAsRead = async (id: string, personal: boolean): Promise<void> => {
  const notificationsRef = personal ? getUserNotificationsRef() : getNotificationsRef();
  if (!notificationsRef) return;
  const docRef = doc(notificationsRef, id);
  await updateDoc(docRef, { read: true });
};
