export const snackbarAutoHide = 5000;

export const debugJSON = false;
export const debug_i18n = false;
export const debug_docSize = false;
export const debug_authState = false;
export const overrideFirebaseEmulators = false;

export const shortMessageMaxLength = 20;
export const drawerWidth = 48;
export const notificationDrawerWidth = 320;
export const headerHight = 215;

export const locationBasedInviteUrl = (uuid: string) => {
  const { origin } = window.location;
  return `${origin}/welcome?registration=${uuid}`;
};
