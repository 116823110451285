import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iLeasingExternalCustomerOffer } from 'datamodel';

export const LeasingExternalCustomerOffersSlice = createSlice({
  name: 'LeasingExternalCustomerOffers',
  initialState: [] as iLeasingExternalCustomerOffer[],
  reducers: {
    setLeasingExternalCustomerOffersValue: (_, action: PayloadAction<iLeasingExternalCustomerOffer[]>) =>
      action.payload,
  },
});

export const { setLeasingExternalCustomerOffersValue } = LeasingExternalCustomerOffersSlice.actions;

export default LeasingExternalCustomerOffersSlice.reducer;
