import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface iHooverCalendarItem {
  value: string;
}

export const hooverCalendarItemSlice = createSlice({
  name: 'hooverCalendarItem',
  initialState: '',
  reducers: {
    setHooverCalenderItemValue: (_, action: PayloadAction<string>) => action.payload,
  },
});

export const { setHooverCalenderItemValue } = hooverCalendarItemSlice.actions;

export default hooverCalendarItemSlice.reducer;
