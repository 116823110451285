import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iFeatures } from 'datamodel';

const initialState: iFeatures = {
  loaded: false,
  ausfallmanagement: {
    subCategories: false,
  },
};

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeatures: (state, action: PayloadAction<iFeatures>) => ({ ...state, ...action.payload }),
    resetFeatures: () => initialState,
  },
});

export const { setFeatures, resetFeatures } = featuresSlice.actions;
export default featuresSlice.reducer;
