import { iReleaseNote } from 'datamodel';
import { deleteDoc, doc, increment, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from 'localFirebase';
import { getReleaseNotesStateRef } from './general';

export const deleteNotesReactionById = async (id: string): Promise<void> => {
  const notesReactionRef = doc(db, 'notesReactions', id);
  if (!notesReactionRef) return;
  const res = await deleteDoc(notesReactionRef);
  return res;
};

export const saveReleaseNote = async (releaseNoteId: string, note: Partial<iReleaseNote>): Promise<void> => {
  const notesRef = releaseNoteId !== '' ? doc(db, 'releaseNotes', releaseNoteId) : doc(db, 'releaseNotes');
  if (!notesRef) return;
  const res = await setDoc(notesRef, note);
  return res;
};

export const updateReleaseNoteLikesAndHearts = async (
  releaseNoteId: string,
  like: number,
  heart: number,
): Promise<void> => {
  const releaseNoteStateRef = getReleaseNotesStateRef(releaseNoteId);
  if (releaseNoteStateRef && (like === 1 || like === -1)) {
    await setDoc(releaseNoteStateRef, { liked: like === 1 }, { merge: true });
  }
  if (releaseNoteStateRef && (heart === 1 || heart === -1)) {
    await setDoc(releaseNoteStateRef, { hearted: heart === 1 }, { merge: true });
  }
  // await updateReleaseNoteState(releaseNoteId, { liked: like>0 ? true: false, hearted: true });
  const notesRef = doc(db, 'releaseNotes', releaseNoteId);
  if (!notesRef) return;
  const res = await updateDoc(notesRef, {
    likes: increment(like),
    hearts: increment(heart),
  });
  return res;
};

export const updateReleaseNoteState = async (
  releaseNoteId: string,
  update: { read: boolean; readAt?: Timestamp },
): Promise<void> => {
  const releaseNoteStateRef = getReleaseNotesStateRef(releaseNoteId);
  if (!releaseNoteStateRef) return;
  const docToUpdate: { read: boolean; updatedAt: Timestamp; readAt?: Timestamp } = {
    read: update.read,
    updatedAt: Timestamp.now(),
  };
  if (update.readAt) docToUpdate.readAt = update.readAt;
  await setDoc(releaseNoteStateRef, docToUpdate, { merge: true });
};
