import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iLocalHolidays } from 'datamodel';

export const localHolidaysSlice = createSlice({
  name: 'localHolidays',
  initialState: {} as iLocalHolidays,
  reducers: {
    setLocalHolidaysValue: (_, action: PayloadAction<iLocalHolidays>) => action.payload,
  },
});

export const { setLocalHolidaysValue } = localHolidaysSlice.actions;

export default localHolidaysSlice.reducer;
