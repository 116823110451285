import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iLeasingEmployeeOffer } from 'datamodel';

export const leasingEmployeeOffersSlice = createSlice({
  name: 'leasingEmployeeOffers',
  initialState: [] as iLeasingEmployeeOffer[],
  reducers: {
    setLeasingEmployeeOffersValue: (_, action: PayloadAction<iLeasingEmployeeOffer[]>) => action.payload,
  },
});

export const { setLeasingEmployeeOffersValue } = leasingEmployeeOffersSlice.actions;

export default leasingEmployeeOffersSlice.reducer;
