import { checkTenantId, checkUserId } from 'database';
import { iLeasingDispatcherSettings, iUser } from 'datamodel';
import { DocumentData, DocumentReference, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from 'localFirebase';

const debug = false;
export const getUserRef = (): DocumentReference<DocumentData> | false => {
  if (debug) console.log('getUserRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const userId = checkUserId();
  if (!userId) return false;
  const user = doc(db, 'leasingCompanies', tenantId, 'users', userId);
  return user;
};

export const getUserDoc = async (): Promise<iUser | false> => {
  const userRef = getUserRef();
  if (!userRef) return false;
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) return { id: userDoc.id, ...userDoc.data() } as iUser;
  return false;
};

export const getAUserRef = (uid: string, pTenantId?: string): DocumentReference<DocumentData> | false => {
  if (debug) console.log('getAUserRef');
  const tenantId = pTenantId || checkTenantId();
  if (!tenantId) return false;
  const user = doc(db, 'leasingCompanies', tenantId, 'users', uid);
  return user;
};

export const getAUserDoc = async (uid: string, pTenantId?: string): Promise<iUser | false> => {
  const userRef = getAUserRef(uid, pTenantId);
  if (!userRef) return false;
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) return { id: userDoc.id, ...userDoc.data() } as iUser;
  return false;
};

export const updateUser = async (value: Partial<iUser>): Promise<void> => {
  if (debug) console.log('updateUser');
  const userRef = getUserRef();
  if (!userRef) return;
  const res = await updateDoc(userRef, value);
  return res;
};

export const updateLeasingDispatcherSettings = async (settings: iLeasingDispatcherSettings): Promise<void> => {
  if (debug) console.log('updateLeasingDispatcherSettings');
  const userRef = getUserRef();
  if (!userRef) return;
  const res = await updateDoc(userRef, { leasingDispatcherSettings: settings });
  return res;
};
