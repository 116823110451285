import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iUser } from 'datamodel';

interface iPoolUserDetailsState {
  open: boolean;
  user: iUser | null;
}

const initialState = {
  open: false,
  user: null as iUser | null,
};

export const poolUserDetailsSlice = createSlice({
  name: 'poolUserDetails',
  initialState,
  reducers: {
    setPoolUserDetailsValue: (state, action: PayloadAction<iPoolUserDetailsState>) => action.payload,
  },
});

export const { setPoolUserDetailsValue } = poolUserDetailsSlice.actions;

export default poolUserDetailsSlice.reducer;
