type Json = string | number | boolean | null | { [key: string]: Json | undefined } | Json[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertToJson = (obj: any): Json => {
  if (obj === null) {
    return null;
  } else if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
    return obj;
  } else if (Array.isArray(obj)) {
    return obj.map(convertToJson);
  } else if (typeof obj === 'object') {
    const result: { [key: string]: Json | undefined } = {};
    for (const key in obj) {
      result[key] = convertToJson(obj[key]);
    }
    return result;
  } else {
    throw new Error('Invalid object');
  }
};
