import { hexToRGB } from '../../general/general';
import * as colors from '../colors';

export const crewlinq = {
  scrollbarTrack: colors.products.crewlinq.primaryLight,
  scrollbarThumb: colors.products.crewlinq.primary, // colors.secondaryLight,
  theme: {
    primaryColor: {
      primary: colors.products.crewlinq.primary,
      primaryLight: colors.products.crewlinq.primaryLight,
      primaryDark: colors.products.crewlinq.primaryDark,
      primaryContrastText: colors.white,
      primaryRgba: colors.products.crewlinq.primaryRgba,
    },
    secondaryColor: {
      secondary: colors.secondary,
      secondaryLight: colors.secondaryLight,
      secondaryDark: colors.secondaryDark,
      secondaryContrastText: colors.white,
      secondaryRgba: colors.secondaryRgba,
    },
    text: {
      primary: colors.products.crewlinq.secondaryFontColor,
      secondary: colors.products.crewlinq.secondaryFontColor,
    },
    errorColor: {
      error: colors.products.error.primary,
    },
    muiCardHeader: {
      textColor: colors.products.core.primary, // Gets contrast text color of specified color
      backgroundColor: colors.products.core.primary,
    },
    muiDialogTitle: {
      textColor: colors.white,
      backgroundColor: colors.products.crewlinq.primary,
    },
  },
  headerBar: {
    backgroundColor: {
      hastItemsOnDay: colors.products.crewlinq.primary,
      noItemsOnDay: colors.products.crewlinq.primaryLight,
      default: colors.products.crewlinq.primary,
      employeeName: colors.products.crewlinq.primary,
    },
  },
  canvas: {
    backgroundColor: colors.surfaceDark,
  },
  calendar: {
    headers: {
      today: colors.products.crewlinq.primaryRgba(0.75),
      other: colors.surfaceDark,
    },
    pool: {
      accepted: colors.lights.green.light,
      rejected: colors.lights.red.light,
      offered: colors.lights.green.light,
      new: colors.lights.yellow.light,
    },
    darkPool: {
      accepted: colors.lights.green.normal,
      rejected: colors.lights.red.normal,
      //Change next line to red to highlight the icon for the pool admin that it is pending the users feedback
      offered: colors.lights.green.normal,
      new: colors.lights.yellow.dark,
    },
    poolNotAdmin: {
      accepted: colors.lights.green.light,
      rejected: colors.lights.red.light,
      offered: colors.lights.green.light,
      new: colors.lights.red.light,
    },
    darkPoolNotAdmin: {
      accepted: colors.lights.green.normal,
      rejected: colors.lights.red.normal,
      offered: colors.lights.green.normal,
      new: colors.lights.red.normal,
    },
    leasing: {
      dark: {
        new: colors.lights.neutral.normal,
        completed: colors.lights.green.normal,
        cancelled: colors.lights.red.dark,
        hasOffer: colors.lights.yellow.dark,
        accepted: colors.lights.green.normal,
      },
      light: {
        new: colors.lights.neutral.lightest,
        completed: colors.lights.green.light,
        cancelled: colors.lights.red.normal,
        hasOffer: colors.lights.yellow.light,
        accepted: colors.lights.green.light,
      },
    },
    leasingToCustomer: {
      dark: {
        new: colors.lights.yellow.dark,
        accepted: colors.lights.green.normal,
        other: colors.lights.red.normal,
      },
      light: {
        new: colors.lights.yellow.light,
        accepted: colors.lights.green.light,
        other: colors.lights.red.light,
      },
    },
    offer: {
      dark: {
        cancelled: colors.lights.red.normal,
        rejected: colors.lights.neutral.light,
        isAssigned: colors.lights.green.normal,
        accepted: colors.lights.yellow.dark,
        other: colors.lights.red.normal,
      },
      light: {
        cancelled: colors.lights.neutral.lighter,
        rejected: colors.lights.neutral.lighter,
        isAssigned: colors.lights.green.light,
        accepted: colors.lights.yellow.light,
        other: colors.lights.red.light,
      },
    },
  },
  overview: {
    available: hexToRGB(colors.products.grey.light, 0.75), // Light grey is still to dark...
    multiDayIsOption: colors.products.grey.normal,
    alsoAvailable: hexToRGB(colors.products.grey.light, 0.33),
    openOffer: colors.products.crewlinq.primary,
    openOfferTransparent: colors.products.crewlinq.primaryDark,
    offered: colors.lights.yellow.normal,
    assigned: colors.lights.green.normal,
    rejected: colors.lights.red.normal,
    cancelledByLeasing: colors.lights.red.light,
    offerToCustomer: {
      borderColor: colors.products.crewlinq.primaryDark,
      iconColor: colors.white,
    },
    notAvailable: {
      off: colors.crewlinqNotAvailable.off,
      desiredOff: colors.crewlinqNotAvailable.off,
      azk: colors.crewlinqNotAvailable.off,
      sickWithAU: colors.crewlinqNotAvailable.sick,
      sickWithoutAU: colors.crewlinqNotAvailable.sick,
      sickChild: colors.crewlinqNotAvailable.sick,
      paidVacation: colors.crewlinqNotAvailable.vacation,
      unpaidVacation: colors.crewlinqNotAvailable.vacation,
      shiftNoReported: colors.crewlinqNotAvailable.off,
      guarantee: colors.crewlinqNotAvailable.off,
      shiftDeclined: colors.crewlinqNotAvailable.off,
      parentalLeave: colors.crewlinqNotAvailable.other,
      maternityProtection: colors.crewlinqNotAvailable.other,
      employmentBan: colors.crewlinqNotAvailable.other,
    },
  },
  sideBarLeft: {
    activeItem: colors.products.crewlinq.primaryDark,
    inactiveItem: colors.products.crewlinq.primary,
    activeItemBackground: colors.products.crewlinq.primaryRgba(0.33),
  },
  header: {
    fontColor: colors.products.grey.normal,
  },
  employeeBar: {
    hasConflict: colors.lights.red.dark,
    iconColor: colors.products.crewlinq.primaryLight,
  },
};
