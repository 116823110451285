import { checkCustomerId, checkUserId } from 'database';
import { iNews } from 'datamodel';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  increment,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { db } from 'localFirebase';

export const getNewsRef = () => {
  const customerId = checkCustomerId();
  const uid = checkUserId();
  if (!customerId) return false;
  if (!uid) return false;
  const customerNews = collection(db, 'customers', customerId, 'news');
  return customerNews;
};

export const getNewsStateRef = (id: string) => {
  const customerId = checkCustomerId();
  const uid = checkUserId();
  if (!customerId) return false;
  if (!uid) return false;
  const customerNews = doc(db, 'customers', customerId, 'news', id, 'state', uid);
  return customerNews;
};

export const updateNewsState = async (id: string, update: { read: boolean; readAt?: Timestamp }): Promise<void> => {
  const newsStateRef = getNewsStateRef(id);
  if (!newsStateRef) return;
  const docToUpdate: { read: boolean; updatedAt: Timestamp; readAt?: Timestamp } = {
    read: update.read,
    updatedAt: Timestamp.now(),
  };
  if (update.readAt) docToUpdate.readAt = update.readAt;
  await setDoc(newsStateRef, docToUpdate, { merge: true });
};

export const getNewsDocRef = (id: string) => {
  const newsRef = getNewsRef();
  if (!newsRef) return false;
  const newsDoc = doc(newsRef, id);
  return newsDoc;
};

export const updateNewsLikesAndHearts = async (id: string, like: number, heart: number): Promise<void> => {
  const newsStateRef = getNewsStateRef(id);
  if (newsStateRef && (like === 1 || like === -1)) {
    await setDoc(newsStateRef, { liked: like === 1 }, { merge: true });
  }
  if (newsStateRef && (heart === 1 || heart === -1)) {
    await setDoc(newsStateRef, { hearted: heart === 1 }, { merge: true });
  }
  const newsRef = getNewsDocRef(id);
  if (!newsRef) return;
  const res = await updateDoc(newsRef, {
    likes: increment(like),
    hearts: increment(heart),
  });
  return res;
};

export const deleteNewsById = async (id: string): Promise<void> => {
  const nRef = getNewsRef();
  if (!nRef) return;
  const newsRef = doc(nRef, id);
  if (!newsRef) return;
  const res = await deleteDoc(newsRef);
  return res;
};

export const getNewsById = async (id: string): Promise<iNews | false> => {
  const newsRef = getNewsRef();
  if (!newsRef) return false;
  if (id === '') return false;
  const docData = await getDoc(doc(newsRef, id));
  if (docData.exists()) return { ...docData.data(), id: docData.id } as iNews;
  return false;
};

export const saveNews = async (id: string, note: Partial<iNews>): Promise<string | false> => {
  const newsRef = getNewsRef();
  if (!newsRef) return false;
  if (id) {
    await setDoc(doc(newsRef, id), note);
    return id;
  }
  const newDoc = await addDoc(newsRef, note);
  return newDoc.id;
};
