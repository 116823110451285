import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const hooverLeasingRequestSlice = createSlice({
  name: 'hooverLeasingRequest',
  initialState: '',
  reducers: {
    setHooverLeasingRequestValue: (_, action: PayloadAction<string>) => action.payload,
  },
});

export const { setHooverLeasingRequestValue } = hooverLeasingRequestSlice.actions;

export default hooverLeasingRequestSlice.reducer;
