import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iDeploymentLocation } from 'datamodel';

const initialState: iDeploymentLocation[] = [];

export const deploymentLocationsSlice = createSlice({
  name: 'deploymentLocations',
  initialState,
  reducers: {
    setDeploymentLocationsValue: (state, action: PayloadAction<iDeploymentLocation[]>) => action.payload,
  },
  selectors: {
    getDeploymentLocationById: (state: iDeploymentLocation[], id: string) =>
      state.find((location) => location.id === id),
  },
});

export const { setDeploymentLocationsValue } = deploymentLocationsSlice.actions;
export const { getDeploymentLocationById } = deploymentLocationsSlice.selectors;

export default deploymentLocationsSlice.reducer;
