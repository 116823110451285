import { ArrayMaxSize, IsArray, IsBase64, IsEmail, IsMimeType, IsString } from 'class-validator';

export class CreateEmailDto {
  @IsArray()
  @IsEmail({ allow_underscores: true }, { each: true })
  // The limit set by Azure is 50: https://learn.microsoft.com/en-us/azure/communication-services/concepts/service-limits#email
  @ArrayMaxSize(50)
  recipients: string[];
  @IsString()
  subject: string;
  @IsString()
  messageHTML: string;
  @IsString()
  messagePlainText: string;

  @IsArray()
  // Randomly selected maximum size, it should be limited, to what value? The future will tell.
  // At least it will limit the risk of abuse.
  @ArrayMaxSize(10)
  attachments: AttachmentDto[];
}

export class AttachmentDto {
  @IsString()
  name: string;
  @IsString()
  contentId: string;
  @IsMimeType()
  contentType: string;
  @IsBase64()
  contentInBase64: string;
}

export enum KnownEmailSendStatus {
  /** NotStarted */
  NotStarted = 'NotStarted',
  /** Running */
  Running = 'Running',
  /** Succeeded */
  Succeeded = 'Succeeded',
  /** Failed */
  Failed = 'Failed',
  /** Canceled */
  Canceled = 'Canceled',
}

export class EmailStatusDto {
  status: KnownEmailSendStatus;
  message: string;
}
