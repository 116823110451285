import { checkCustomerId, checkTenantId, checkUserId } from 'database';
import { iUser } from 'datamodel';
import { DocumentData, DocumentReference, doc, getDoc } from 'firebase/firestore';
import { db } from 'localFirebase';

export const getUserRef = (): DocumentReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!customerId || !tenantId) return false;
  const userId = checkUserId();
  if (!userId) return false;
  const user = doc(db, 'customers', customerId, 'houses', tenantId, 'users', userId);
  return user;
};

export const getUserDoc = async (): Promise<iUser | false> => {
  const userRef = getUserRef();
  if (!userRef) return false;
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) return { id: userDoc.id, ...userDoc.data() } as iUser;
  return false;
};

export const getAUserRef = (
  uid: string,
  pTenantId?: string,
  pCustomerId?: string,
): DocumentReference<DocumentData> | false => {
  const tenantId = pTenantId || checkTenantId();
  const customerId = pCustomerId || checkCustomerId();
  if (!customerId || !tenantId) return false;
  const user = doc(db, 'customers', customerId, 'houses', tenantId, 'users', uid);
  return user;
};

export const getAUserDoc = async (uid: string, pTenantId?: string, pCustomerId?: string): Promise<iUser | false> => {
  const userRef = getAUserRef(uid, pTenantId, pCustomerId);
  if (!userRef) return false;
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) return { id: userDoc.id, ...userDoc.data() } as iUser;
  return false;
};
