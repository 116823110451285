import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addPoolAbsenceType as addPoolAbsenceTypeDoc,
  updatePoolAbsenceType as updatePoolAbsenceTypeDoc,
  removePoolAbsenceType as removePoolAbsenceTypeDoc,
} from 'database/crewlinq';
import type { iPoolAbsenceType } from 'datamodel';
import { Timestamp } from 'firebase/firestore';
import type { RootState } from 'rdx/store';

export const addPoolAbsenceType = createAsyncThunk<
  iPoolAbsenceType,
  Omit<iPoolAbsenceType, 'id' | 'createdAt' | 'updatedAt' | 'position'>,
  { state: RootState }
>('poolAbsenceTypes/add', async (addedPoolAbsenceType, { rejectWithValue, getState }) => {
  try {
    const now = Timestamp.now();
    const newPoolAbsenceType = {
      ...addedPoolAbsenceType,
      createdAt: now,
      updatedAt: now,
      position: getState().poolAbsenceTypes.length,
    };
    const doc = await addPoolAbsenceTypeDoc(newPoolAbsenceType);
    if (!doc) {
      throw new Error('Failed to add pool absence type');
    }
    return { ...newPoolAbsenceType, id: doc.id };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removePoolAbsenceType = createAsyncThunk<string, string>(
  'poolAbsenceTypes/update',
  async (id, { rejectWithValue }) => {
    try {
      await removePoolAbsenceTypeDoc(id);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePoolAbsenceType = createAsyncThunk<iPoolAbsenceType, iPoolAbsenceType>(
  'poolAbsenceTypes/update',
  async (updatedPoolAbsenceType, { rejectWithValue }) => {
    try {
      await updatePoolAbsenceTypeDoc(updatedPoolAbsenceType.id, updatedPoolAbsenceType);
      return updatedPoolAbsenceType;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: iPoolAbsenceType[] = [];

export const poolAbsenceTypesSlice = createSlice({
  name: 'poolAbsenceTypes',
  initialState,
  reducers: {
    setPoolAbsenceTypesValue: (_, action: PayloadAction<iPoolAbsenceType[]>) => action.payload,
  },
});

export const { setPoolAbsenceTypesValue } = poolAbsenceTypesSlice.actions;

export default poolAbsenceTypesSlice.reducer;
