import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iLeasingExternalCustomer } from 'datamodel';

export const leasingExternalCustomersSlice = createSlice({
  name: 'leasingExternalCustomers',
  initialState: [] as iLeasingExternalCustomer[],
  reducers: {
    setLeasingExternalCustomersValue: (_, action: PayloadAction<iLeasingExternalCustomer[]>) => action.payload,
  },
});

export const { setLeasingExternalCustomersValue } = leasingExternalCustomersSlice.actions;

export default leasingExternalCustomersSlice.reducer;
