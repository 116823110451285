import { checkCustomerId, checkTenantId, checkUserId } from 'database';
import { iCrewLinQAddressBookEntry, iDeploymentLocation, iHouse, iHouseSettings, iVolunteerOffer } from 'datamodel';
import {
  addDoc,
  arrayRemove,
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { db } from 'localFirebase';
import { getOffersRef } from './general';

export const getHouseRef = (): DocumentReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!customerId || !tenantId) return false;
  const house = doc(db, 'customers', customerId, 'houses', tenantId);
  return house;
};

export const getHouseDoc = async (): Promise<iHouse | false> => {
  const houseRef = getHouseRef();
  if (!houseRef) return false;
  const houseDoc = await getDoc(houseRef);
  if (houseDoc.exists()) return { id: houseDoc.id, ...houseDoc.data() } as iHouse;
  return false;
};

export const updateHouse = async (value: Partial<iHouse>): Promise<void> => {
  const houseRef = getHouseRef();
  if (!houseRef) return;
  const res = await updateDoc(houseRef, value);
  return res;
};

export const updateHouseSettings = async (value: Partial<iHouseSettings>): Promise<void> => {
  const houseRef = getHouseRef();
  if (!houseRef) return;
  const res = await updateDoc(houseRef, { settings: value });
  return res;
};

export const deleteFieldFromHouseSettings = async (field: string): Promise<void> => {
  const houseRef = getHouseRef();
  if (!houseRef) return;
  const res = await updateDoc(houseRef, { settings: arrayRemove(field) });
  return res;
};

export const getAddressBookEntry = async (): Promise<iCrewLinQAddressBookEntry | false> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const entryRef = doc(db, 'customers', 'public', 'addressBook', tenantId);
  const entryDoc = await getDoc(entryRef);
  if (entryDoc.exists()) return { id: entryDoc.id, ...entryDoc.data() } as iCrewLinQAddressBookEntry;
  return false;
};

export const updateAddressBookEntry = async (value: Partial<iCrewLinQAddressBookEntry>): Promise<void> => {
  const userId = checkUserId();
  const tenantId = checkTenantId();
  const customerId = checkCustomerId();
  if (!tenantId || !customerId || !userId) return;
  const objectToSet = { ...value };
  objectToSet.customerId = customerId;
  objectToSet.tenantId = tenantId;
  if (!value.public) objectToSet.public = false;
  if (!value.updatedAt) objectToSet.updatedAt = Timestamp.now();
  if (!value.updatedBy) objectToSet.updatedBy = { id: userId, name: '' };
  if (!value.createdAt) objectToSet.createdAt = Timestamp.now();
  if (!value.createdBy) objectToSet.createdBy = { id: userId, name: '' };
  const entryRef = doc(db, 'customers', 'public', 'addressBook', tenantId);
  const res = await setDoc(entryRef, objectToSet);
  return res;
};

export const getVolunteerOffersRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!customerId || !tenantId) return false;
  const ref = collection(db, 'customers', customerId, 'houses', tenantId, 'volunteerOffers');
  return ref;
};

export const updateVolunteerItem = async (id: string, value: Partial<iVolunteerOffer>): Promise<void> => {
  const ref = getVolunteerOffersRef();
  if (!ref) return;
  const res = await updateDoc(doc(ref, id), value);
  return res;
};

export const getVolunteerOfferRef = (id: string): DocumentReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!customerId || !tenantId) return false;
  const ref = doc(db, 'customers', customerId, 'houses', tenantId, 'volunteerOffers', id);
  return ref;
};

export const addVolunteerItem = async (value: Partial<iVolunteerOffer>): Promise<void> => {
  const ref = getVolunteerOffersRef();
  if (!ref) return;
  await addDoc(ref, value);
};

export const getDeploymentLocationsRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!customerId || !tenantId) return false;
  const ref = collection(db, 'customers', customerId, 'houses', tenantId, 'deploymentLocations');
  return ref;
};

export const getDeploymentLocationRefById = (id: string): DocumentReference<DocumentData> | false => {
  const ref = getDeploymentLocationsRef();
  if (!ref) return false;
  return doc(ref, id);
};

export const updateDeploymentLocation = async (id: string, value: Partial<iDeploymentLocation>): Promise<void> => {
  const ref = getDeploymentLocationsRef();
  if (!ref) return;
  const res = await updateDoc(doc(ref, id), value);
  return res;
};

export const addDeploymentLocation = async (value: Partial<iDeploymentLocation>): Promise<void> => {
  const ref = getDeploymentLocationsRef();
  if (!ref) return;
  await addDoc(ref, value);
};

export const getFutureOfferRef = (): DocumentReference<DocumentData, DocumentData> | false => {
  const offerRef = getOffersRef();
  if (!offerRef) return false;
  const futureIdRef = doc(offerRef);
  return futureIdRef;
};
