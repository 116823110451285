/**
 * The userObject is the Auth object from Firebase
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';

export const userObjectSlice = createSlice({
  name: 'userObject',
  initialState: null as User | null,
  reducers: {
    setUserObjectValue: (state, action: PayloadAction<User | null>) => action.payload,
  },
});

// Action creators are generated for each case reducer function
export const { setUserObjectValue } = userObjectSlice.actions;

export default userObjectSlice.reducer;
