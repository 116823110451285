import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iLeasingQualification } from 'datamodel';

export const leasingQualificationsSlice = createSlice({
  name: 'leasingQualifications',
  initialState: [] as iLeasingQualification[],
  reducers: {
    setLeasingQualificationsValue: (_, action: PayloadAction<iLeasingQualification[]>) => action.payload,
  },
});

export const { setLeasingQualificationsValue } = leasingQualificationsSlice.actions;

export default leasingQualificationsSlice.reducer;
