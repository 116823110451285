import { checkTenantId, checkUserId } from 'database';
import { iDepartment, iLeasingCustomerContactInfo, iShift } from 'datamodel';
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Query,
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { db } from 'localFirebase';

const debug = false;

export const getCustomerRef = (customerId: string, houseId: string): DocumentReference<DocumentData> | false => {
  if (debug) console.log('getCustomerRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const customer = doc(db, 'customers', customerId, 'houses', houseId);
  return customer;
};

export const deletePreRegisteredCustomer = async (id: string): Promise<void> => {
  const customerRef = collection(db, 'invites');
  await deleteDoc(doc(customerRef, id));
};

export const inviteCustomer = async (
  customer: Partial<iLeasingCustomerContactInfo>,
  uuid: string,
): Promise<boolean> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const customerRef = collection(db, 'invites');
  await addDoc(customerRef, {
    ...customer,
    createdAt: Timestamp.now(),
    createdBy: checkUserId(),
    leasingCompanyId: tenantId,
    uuid,
  });
  return true;
};

export const getPreRegisteredCustomer = async (uuid: string): Promise<iLeasingCustomerContactInfo | false> => {
  const customerRef = collection(db, 'invites');
  const customerSnap = await getDocs(query(customerRef, where('uuid', '==', uuid)));
  if (customerSnap.empty) return false;
  return { ...customerSnap.docs[0].data(), id: customerSnap.docs[0].id } as iLeasingCustomerContactInfo;
};

export const getPreRegisteredCustomers = (): Query<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const customerRef = collection(db, 'invites');
  const q = query(customerRef, where('leasingCompanyId', '==', tenantId), where('uuid', '!=', 'resolved'));
  return q;
};

export const getCustomerDepartments = async (customerId: string, houseId: string): Promise<iDepartment[] | false> => {
  if (debug) console.log('getCustomerDepartments');
  const customerRef = getCustomerRef(customerId, houseId);
  if (!customerRef) return false;
  const depRef = collection(customerRef, 'departments');
  if (!depRef) return false;
  const depSnap = await getDocs(depRef);
  const departments = depSnap.docs.map((depDoc) => ({ id: depDoc.id, ...depDoc.data() } as iDepartment));
  return departments;
};

export const getContactInformationRef = (): CollectionReference<DocumentData> | false => {
  if (debug) console.log('getContactInformationRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const contactInformation = collection(db, 'leasingCompanies', tenantId, 'contactInformation');
  return contactInformation;
};

export const getCustomerShifts = async (customerId: string, houseId: string): Promise<iShift[] | false> => {
  if (debug) console.log('getCustomerShifts');
  const customerRef = getCustomerRef(customerId, houseId);
  if (!customerRef) return false;
  const shiftRef = collection(customerRef, 'shifts');
  if (!shiftRef) return false;
  const shiftSnap = await getDocs(shiftRef);
  const shifts = shiftSnap.docs.map((shiftDoc) => ({ id: shiftDoc.id, ...shiftDoc.data() } as iShift));
  return shifts;
};
