import store from 'rdx/store';

export const checkTenantId = () => {
  const tenantId = store.getState().appState.tenantId || '';
  if (!tenantId) return false;
  return tenantId;
};

export const checkCustomerId = () => {
  const customerId = store.getState().appState.customerId || '';
  if (!customerId) return false;
  return customerId;
};

export const checkUserId = () => {
  const localStore = store.getState();
  const userId = localStore.userObject?.uid || localStore.user?.id || localStore.appState?.uid || '';
  if (!userId) return false;
  return userId;
};

export * as crewlinq from './crewlinq';
export * as general from './general';
export * as internal from './internal';
export * as leasing from './leasing';
export * as users from './users';
