export enum eSichtbarIn {
  AlleProgramme,
  NurNG,
  NurPEP,
}

/**
 * @description Connext Vivendi PEP Dienst -> iShift
 * @see iShift
 */
export interface iCVPDienst {
  id: number;
  Bezeichnung: string;
  Kuerzel: string;
  Zusatz: string;
  DienstgruppenId: number;
  SchichtId: number;
  Gueltigkeit: {
    ab: string;
    bis: string;
  };
  Diensttag: number;
  Icon: string;
  Rang: number;
  Bemerkung: string;
  Gesperrt: boolean;
  Dienstzeiten: {
    AbZeit: string;
    BisZeit: string;
    Pause: number;
    AufgabenId: number;
    SonderzeitId: number;
  }[];
  BereichIds: number[];
  DiensttypIds: number[];
}

/**
 * @description Connext Vivendi PEP Qualifikation -> iQualification
 * @see iQualification
 */
export interface iCVPQualifikation {
  id: number;
  Bezeichnung: string;
  Kuerzel: string;
  Anrechnungsfaktor: number;
}

/**
 * @description Connext Vivendi PEP Bereich -> iDepartment
 * @see iDepartment
 */
export interface iCVPBereich {
  id: number;
  parentId: number;
  Bezeichnung: string;
  Kuerzel: string;
  Zusatz: string;
  Bemerkung: string;
  GueltigBis: string;
  Kostenstelle: string;
  Mandantennummer: string;
  Adresse: iCVPAdresse;
  Ansprechpartner: iCVPAnsprechpartner;
  IstGruppe: boolean;
  NutztParentAdresse: boolean;
  Position: number;
  SichtbarIn: eSichtbarIn;
  Synchronisiert: boolean;
}

export interface iCVPAdresse {
  Strasse: string;
  Plz: string;
  Ort: string;
  Bundesland: string;
  Land: string;
  Telefon1: string;
  Telefon2: string;
  TelefonIntern: string;
  Mobil: string;
  Fax: string;
  Email: string;
  EmailIntern: string;
  Web: string;
}

export interface iCVPAnsprechpartner {
  id: number;
  Name: string;
  Vorname: string;
  Titel: string;
  Anrede: string;
  Bemerkung: string;
  Adresse: iCVPAdresse;
}

export interface iCVPMitarbeiter {
  id: number; // integer, minimum: 1
  Nachname: string; // string, maxLength: 70
  Vorname: string; // string, maxLength: 50
  Personalnummer: string; // string, maxLength: 36
  Handzeichen: string; // string, maxLength: 66
  Beschaeftigtennummer: string; // string, maxLength: 66
  Beschaeftigungsart: 'Bewerber' | 'Arbeitnehmer' | 'WfbMBeschaeftigter';
  Geburtsdatum: string; // date
  Geschlecht: 'maennlich' | 'weiblich' | 'neutral' | 'unbekannt';
  Anrede: iCVPAnrede;
  Titel: string; // string, maxLength: 50
  Namenszusatz: string; // string, maxLength: 70
  Geburtsort: string; // string, maxLength: 70
  Geburtsname: string; // string, maxLength: 70
  Sozialversicherungsnummer: string; // string, maxLength: 30
  Kostenstelle: string; // string, maxLength: 50
  Monatsbruttolohn: number; // number, minimum: 0
  Stundensatz: number; // number, minimum: 0
  Bankverbindung: null;
  Konfession: null;
  Familienstand: null;
  Nationalitaet: null;
  Krankenkasse: null;
  Verguetungsgruppe: null;
  Adresse: null;
  Beschaeftigungsdatum: string; // date
  Eintrittsdatum: string; // date
  AufenthaltserlaubnisBis: string; // date
  ArbeitserlaubnisBis: string; // date
  FuehrungszeugnisBis: string; // date
  ProbezeitBis: string; // date
  Jubilaeumsdatum: string; // date
  Austrittsdatum: string; // date
  Austrittsgrund: iCVPAustrittsgrund;
  Berufsgruppen: iCVPBerufsgruppenzuordnung[];
  Qualifikationen: iCVPQualifikationszuordnung[];
  Behinderungen: null;
  Funktionen: iCVPFunktionszuordnung[];
  Bereiche: iCVPBereichszuordnung[];
  Statistikgruppen: null;
  Regelarbeitszeiten: iCVPRegelarbeitszeit[];
  Vertraege: iCVPVertragszuordnung[];
  Freifelder: iCVPFreifeld[]; // maxLength: 10
  Transponder: null;
  Bewertungsbetraege: null;
  Mehrdienstleistungspauschalen: null;
  Arbeitsunterbrechungen: iCVPArbeitsunterbrechung[];
  Entbindungen: null;
  Krankenkassen: null;
  Schluesselpakete: null;
}

export interface iCVPAnrede {
  id: number;
  Bezeichnung: string;
  Kuerzel: string;
  Geschlecht: 'maennlich' | 'weiblich' | 'neutral' | 'unbekannt';
}

export interface iCVPAustrittsgrund {
  id: number;
  Bezeichnung: string;
  Kuerzel: string;
}

export interface iCVPBerufsgruppenzuordnung {
  id: number;
  Gueltigkeit: iCVPDateTimeRange;
  Berufsgruppe: iCVPBerufsgruppe;
}

export interface iCVPDateTimeRange {
  ab: string;
  bis: string;
}

export interface iCVPBerufsgruppe {
  id: number;
  Bezeichnung: string;
  Kuerzel: string;
  Schluessel: string;
  Anrechnungsfaktor: number;
}

export interface iCVPQualifikationszuordnung {
  id: number;
  Gueltigkeit: iCVPDateTimeRange;
  Qualifikation: iCVPQualifikation;
  Anrechnungsfaktor: number;
}

export interface iCVPFunktionszuordnung {
  id: number;
  Gueltigkeit: iCVPDateTimeRange;
  Funktion: iCVPFunktion;
}

export interface iCVPFunktion {
  id: number;
  Bezeichnung: string;
  Kuerzel: string;
  Anrechnungsfaktor: number;
}

export interface iCVPBereichszuordnung {
  id: number;
  Gueltigkeit: iCVPDateTimeRange;
  Bereich: iCVPBereich;
  StammBereich: boolean;
  AbweichendeKostenstelle: string;
  Stelle: iCVPStelle;
  VKStellenanteil: number;
}

export interface iCVPStelle {
  id: number;
  Bezeichnung: string;
  Kuerzel: string;
  Bemerkung: string;
  Gueltigkeit: iCVPDateTimeRange;
  Anzahl: number; // Die Anzahl der Mitarbeiter.
  Planwert: number; // Der Planwert in Euro zur Auswertung z.B. im Vivendi-Kennzahlencockpit.
}

export interface iCVPRegelarbeitszeit {
  id: number; // integer, minimum: 1
  Name: string; // string, maxLength: 255
  Bemerkung: string; // string, maxLength: 255
  Gueltigkeit: iCVPDateTimeRange;
  Zeitraum: 'Tag' | 'Woche' | 'Monat';
  Stunden: number; // number, up to 6 decimal places
  Tagewoche: number; // number, minimum: 0, maximum: 7
  Stelle: number; // number, up to 6 decimal places
  TaggenaueVerteilung: 'Keine' | 'Wochentagverteilung' | 'Stundenfolge';
  Wochentagverteilung: iCVPWochentagverteilung;
  Stundenfolge: iCVPStundenfolge;
  Startwoche: number; // integer, minimum: 1, maximum: 111
}

export interface iCVPWochentagverteilung {
  Montag: number; // number, minimum: 0, maximum: 24, up to 6 decimal places
  Dienstag: number; // number, minimum: 0, maximum: 24, up to 6 decimal places
  Mittwoch: number; // number, minimum: 0, maximum: 24, up to 6 decimal places
  Donnerstag: number; // number, minimum: 0, maximum: 24, up to 6 decimal places
  Freitag: number; // number, minimum: 0, maximum: 24, up to 6 decimal places
  Samstag: number; // number, minimum: 0, maximum: 24, up to 6 decimal places
  Sonntag: number; // number, minimum: 0, maximum: 24, up to 6 decimal places
}

export interface iCVPStundenfolge {
  id: number; // integer, minimum: 1
  Bezeichnung?: string; // string, maxLength: 70
  Zusatz?: string; // string, maxLength: 90
  Gueltigkeit: iCVPDateTimeRange;
  Bereiche?: iCVPBereichsreferenz[];
  Wochen: iCVPStundenfolgenWoche[];
}

export interface iCVPStundenfolgenWoche {
  stundenfolgeId: number; // integer, minimum: 1
  Woche: number; // integer
  Wochentagverteilung: iCVPWochentagverteilung;
}

export interface iCVPBereichsreferenz {
  id: number; // integer, minimum: 1
  parentId?: number; // integer, minimum: 1
  Bezeichnung?: string; // string, maxLength: 255
  Kuerzel?: string; // string, maxLength: 20
  Zusatz?: string; // string, maxLength: 255
  Bemerkung?: string; // string, maxLength: 255
  GueltigBis?: string; // string, format: date
  Kostenstelle?: string; // string, maxLength: 20
  Mandantennummer?: string; // string, maxLength: 20
}

export interface iCVPVertragszuordnung {
  id: number; // integer, minimum: 1
  Gueltigkeit: iCVPDateTimeRange;
  Vertrag: iCVPVertrag;
}

export interface iCVPVertrag {
  id: number; // integer, minimum: 1
  parentId?: number; // integer, minimum: 1
  Bezeichnung?: string; // string, maxLength: 255
  Kuerzel?: string; // string, maxLength: 20
  Bemerkung?: string; // string, maxLength: 200
}

export interface iCVPFreifeld {
  Nummer: number; // integer, minimum: 1, maximum: 10
  Text?: string; // string, maxLength: 255
}
export interface iCVPArbeitsunterbrechung {
  id: number; // integer, minimum: 1
  Name?: string; // string, maxLength: 255
  Bemerkung?: string; // string, maxLength: 255
  Gueltigkeit: iCVPDateTimeRange;
  Grund:
    | 'Elternzeit'
    | 'Pflegezeit'
    | 'Austritt'
    | 'TeilzeitInElternzeit'
    | 'TeilzeitInPflegezeit'
    | 'Beschaeftigungsverbot'
    | 'Langzeitkrank'
    | 'Mutterschutz'
    | 'Sonderurlaub'
    | 'ErwerbsminderungsrenteOhneDienstzeit'
    | 'FreistellungsphaseAltersteilzeit'
    | 'ArbeitsunfaehigkeitGemeldet'
    | 'ArbeitsunfaehigkeitNachgewiesen'
    | 'OhneUrlaubOhneSollstunden'
    | 'MitUrlaubOhneSollstunden'
    | 'OhneUrlaubMitSollstunden'
    | 'MitUrlaubMitSollstunden';
}

export interface iCVPDiensteintrag {
  Bemerkung: string;
  BereichId: number;
  Datum: string;
  DienstId: number;
  Diensttag: number;
  Dienstnummer: number;
  GeandertAm: string;
  MitarbeiterId: number;
  Zeiten: {
    AbZeit: string;
    BisZeit: string;
    Pause: number;
    AufgabenId: number;
    Bemerkung: string;
  }[];
}
