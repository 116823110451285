import { getEnvironment } from 'utils/toolkit';

export interface iFirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  isProduction: boolean;
  reCaptchaV3Key: string;
}

const crewlinqAppConfig = {
  apiKey: 'AIzaSyB2XNDI08KGNhJmpwozEdpGrHjq9oRdAA8',
  authDomain: 'crewlinq-app.firebaseapp.com',
  projectId: 'crewlinq-app',
  storageBucket: 'crewlinq-app.appspot.com',
  messagingSenderId: '254508265931',
  appId: '1:254508265931:web:5eb0ce5cc69f1fad71464e',
  measurementId: 'G-PLGM7Z0VNB',
  isProduction: true,
  reCaptchaV3Key: '6LdGx2YbAAAAAF5kt6EHnHiugghgR7ZMO7L4BDKb',
};

const crewlinqDemoConfig = {
  apiKey: 'AIzaSyAnWr35_vZcyPnc9lxcdisPcY6iHPp6nFk',
  authDomain: 'crewlinq-demo.firebaseapp.com',
  projectId: 'crewlinq-demo',
  storageBucket: 'crewlinq-demo.appspot.com',
  messagingSenderId: '678563747935',
  appId: '1:678563747935:web:f38c2a7be8d554450ee3ea',
  isProduction: false,
  measurementId: 'G-1YWSM4E7EZ',
  reCaptchaV3Key: '6LdBn9klAAAAAFOqOBVDE-b_ohFFWmRQv0kC_gjc',
  // Shhhh 6LdBn9klAAAAAChZxQR7I-Y_zLKN4U41RKmA4r3i
};

const crewlinqDevConfig = {
  apiKey: 'AIzaSyC6CLFjt61a8ig5SAPid8ZHSb-19laHHuk',
  authDomain: 'crewlinq-dev.firebaseapp.com',
  projectId: 'crewlinq-dev',
  storageBucket: 'crewlinq-dev.appspot.com',
  messagingSenderId: '949791832323',
  appId: '1:949791832323:web:764a8dc0589720aa095136',
  measurementId: 'G-S1VV5XGXEH',
  isProduction: false,
  reCaptchaV3Key: '6Lenwt4eAAAAAOpyVig8dRGVxcgc-yxBzEQAf6nj',
};

export const firebaseConfig = (): iFirebaseConfig => {
  // Return the correct config depending on the environment
  const env = getEnvironment();
  console.log('Running in environment:', env);
  if (env === 'localDev' || env === 'development') return crewlinqDevConfig;
  if (env === 'demo') return crewlinqDemoConfig;
  return crewlinqAppConfig;
};
