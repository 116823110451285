import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iLeasingEmployee } from 'datamodel';

export const leasingEmployeesSlice = createSlice({
  name: 'leasingEmployees',
  initialState: [] as iLeasingEmployee[],
  reducers: {
    setLeasingEmployeesValue: (_, action: PayloadAction<iLeasingEmployee[]>) => action.payload,
  },
});

export const { setLeasingEmployeesValue } = leasingEmployeesSlice.actions;

export default leasingEmployeesSlice.reducer;
