import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iNotification } from 'datamodel';

const initialState = {
  personal: [] as iNotification[],
  general: [] as iNotification[],
  open: false,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setGeneralNotificationsValue: (state, action: PayloadAction<iNotification[]>) => ({
      ...state,
      general: action.payload,
    }),
    setPersonalNotificationsValue: (state, action: PayloadAction<iNotification[]>) => ({
      ...state,
      personal: action.payload,
    }),
    notificationDrawerOpen: (state) => ({
      ...state,
      open: true,
    }),
    notificationDrawerClose: (state) => ({
      ...state,
      open: false,
    }),
  },
});

export const {
  setGeneralNotificationsValue,
  setPersonalNotificationsValue,
  notificationDrawerOpen,
  notificationDrawerClose,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
