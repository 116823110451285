import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iTimeSlot } from 'datamodel';

const initialState: iTimeSlot[] = [];

export const timeSlotsSlice = createSlice({
  name: 'timeSlots',
  initialState,
  reducers: {
    setTimeSlotsValue: (state, action: PayloadAction<iTimeSlot[]>) => action.payload,
  },
});

// Action creators are generated for each case reducer function
export const { setTimeSlotsValue } = timeSlotsSlice.actions;

export default timeSlotsSlice.reducer;
