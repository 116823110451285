import { iAppState, mergeWithEmptyCustomerHouseSettings, Themes } from 'datamodel';
// const appState = useAppSelector((state) => state.appState);
// const setAppState = (v: iAppState) => dispatch(setAppStateValue(v));

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: iAppState = {
  loggedIn: false,
  house: null,
  isLeasing: false,
  isLeasingCompany: false,
  isInternalUser: false,
  isCrewlinq: false,
  logo: '',
  tenantId: '',
  customerId: '',
  role: null,
  theme: Themes.neutral,
  uid: '',
  appStarted: false,
  authCheckDone: false,
  hasTenant: false,
  userLoaded: false,
  offersLoaded: false,
  restart: false,
  notificationsRegistered: false,
  leasingCompany: null,
  seasonalImage: '',
  floatingSettings: {
    filterDepartments: [],
  },
  deploymentLocationsLoaded: null,
};

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setAppStateValue: (state, action: PayloadAction<iAppState>) => {
      if ((state.isCrewlinq || action.payload.isCrewlinq) && action.payload.house)
        return {
          ...action.payload,
          house: {
            ...action.payload.house,
            settings: mergeWithEmptyCustomerHouseSettings(action.payload.house?.settings),
          },
        };
      return action.payload;
    },
    updateAppStateValue: (state, action: PayloadAction<Partial<iAppState>>) => {
      if ((state.isCrewlinq || action.payload.isCrewlinq) && action.payload.house) {
        const fullSettings = mergeWithEmptyCustomerHouseSettings(action.payload.house?.settings);
        return { ...state, ...action.payload, house: { ...action.payload.house, settings: fullSettings } };
      }
      return { ...state, ...action.payload };
    },
    clearAppState: (state) => ({ ...state, ...initialState }),
  },
});

export const { setAppStateValue, updateAppStateValue, clearAppState } = appStateSlice.actions;
export default appStateSlice.reducer;
