import { checkTenantId } from 'database';
import { iQualification } from 'datamodel';
import { CollectionReference, DocumentData, addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from 'localFirebase';

const debug = false;

export const getQualificationsRef = (): CollectionReference<DocumentData> | false => {
  if (debug) console.log('getQualificationsRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const leasingQualifications = collection(db, 'leasingCompanies', tenantId, 'qualifications');
  return leasingQualifications;
};

export const addQualification = async (value: Partial<iQualification>): Promise<string | false> => {
  if (debug) console.log('addQualification');
  const leasingQualificationsRef = getQualificationsRef();
  if (!leasingQualificationsRef) return false;
  const res = await addDoc(leasingQualificationsRef, value);
  return res.id;
};

export const updateQualification = async (id: string, value: Partial<iQualification>): Promise<boolean> => {
  if (debug) console.log('updateQualification');
  const leasingQualificationsRef = getQualificationsRef();
  if (!leasingQualificationsRef) return false;
  const qualificationRef = doc(leasingQualificationsRef, id);
  const v = value as Partial<iQualification>;
  if (value.id === '') delete v.id;
  await updateDoc(qualificationRef, v);
  return true;
};
