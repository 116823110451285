// const isLoading = useAppSelector((state) => state.isLoading);
// const setIsLoading = (v: boolean) => dispatch(setLoadingValue(v));
// const dispatch = useAppDispatch();
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface iLoadingState {
  isLoading: boolean;
  source: string | null;
}

export const isLoadingSlice = createSlice({
  name: 'isLoading',
  initialState: { isLoading: false, source: null } as iLoadingState,
  reducers: {
    setLoadingValue: (_, action: PayloadAction<boolean | [boolean, string]>) => {
      let isLoading: boolean;
      let source: string | null;
      if (typeof action.payload === 'boolean') {
        isLoading = action.payload;
        source = null;
      } else {
        [isLoading, source] = action.payload;
      }
      // Use this to debug where the loading is coming from
      // console.log('Source:', source);
      return {
        isLoading,
        source: source ?? null,
      };
    },
    setLoadingTrue: (_, action: PayloadAction<string | null>) => {
      const source = action.payload;
      // Use this to debug where the loading is coming from
      // console.log('Source:', source);
      return {
        isLoading: true,
        source,
      };
    },
    setLoadingFalse: () => {
      // Use this to debug where the loading is coming from
      // console.log('Source:', null);
      return {
        isLoading: false,
        source: null,
      };
    },
  },
});

export const { setLoadingValue, setLoadingTrue, setLoadingFalse } = isLoadingSlice.actions;

export default isLoadingSlice.reducer;
