import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iPoolShift } from 'datamodel';

const initialState: iPoolShift[] = [];

export const poolShiftsSlice = createSlice({
  name: 'poolShifts',
  initialState,
  reducers: {
    setPoolShiftsValue: (state, action: PayloadAction<iPoolShift[]>) => action.payload,
  },
});

export const { setPoolShiftsValue } = poolShiftsSlice.actions;

export default poolShiftsSlice.reducer;
