/* eslint-disable max-lines */
export const hexToRGB = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const validateEmailAddress = (email: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const allHolidaysGermany = {
  2024: {
    '20240101': {
      name: 'neujahr',
      label: {
        de: 'Neujahr',
        en: "New Year's Day",
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20240106': {
      name: 'heiligeDreiKoenige',
      label: {
        de: 'Heilige Drei Könige',
        en: 'Epiphany',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
      ],
    },
    '20240308': {
      name: 'internationalerFrauentag',
      label: {
        de: 'Int. Frauentag',
        en: "Int. Women's Day",
      },
      states: [
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
      ],
    },
    '20240329': {
      name: 'karfreitag',
      label: {
        de: 'Karfreitag',
        en: 'Good Friday',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20240331': {
      name: 'ostersonntag',
      label: {
        de: 'Ostersonntag',
        en: 'Easter Sunday',
      },
      states: [
        {
          name: 'Brandenburg',
          short: 'BB',
        },
      ],
    },
    '20240401': {
      name: 'ostermontag',
      label: {
        de: 'Ostermontag',
        en: 'Easter Monday',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20240501': {
      name: 'tagDerArbeit',
      label: {
        de: 'Tag der Arbeit',
        en: 'Labour Day',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20240509': {
      name: 'christiHimmelfahrt',
      label: {
        de: 'Christi Himmelfahrt',
        en: 'Ascension Day',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20240519': {
      name: 'pfingstsonntag',
      label: {
        de: 'Pfingstsonntag',
        en: 'Whit Sunday',
      },
      states: [
        {
          name: 'Brandenburg',
          short: 'BB',
        },
      ],
    },
    '20240520': {
      name: 'pfingstmontag',
      label: {
        de: 'Pfingstmontag',
        en: 'Whit Monday',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20240530': {
      name: 'fronleichnam',
      label: {
        de: 'Fronleichnam',
        en: 'Corpus Christi',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
      ],
    },
    '20240808': {
      name: 'augsburgerFriedensfest',
      label: {
        de: 'Augsburger Friedensfest',
        en: 'Augsburg Peace Festival',
      },
      states: [
        {
          name: 'Bayern',
          short: 'BY',
        },
      ],
    },
    '20240815': {
      name: 'mariaeHimmelfahrt',
      label: {
        de: 'Mariä Himmelfahrt',
        en: 'Assumption of Mary',
      },
      states: [
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
      ],
    },
    '20240920': {
      name: 'weltkindertag',
      label: {
        de: 'Weltkindertag',
        en: "World Children's Day",
      },
      states: [
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20241003': {
      name: 'tagDerDeutschenEinheit',
      label: {
        de: 'Tag der Deutschen Einheit',
        en: 'German Unity Day',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20241031': {
      name: 'reformationstag',
      label: {
        de: 'Reformationstag',
        en: 'Reformation Day',
      },
      states: [
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20241101': {
      name: 'allerheiligen',
      label: {
        de: 'Allerheiligen',
        en: "All Saints' Day",
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
      ],
    },
    '20241120': {
      name: 'bussUndBettag',
      label: {
        de: 'Buß und Bettag',
        en: 'Repentance and Prayer Day',
      },
      states: [
        {
          name: 'Sachsen',
          short: 'SN',
        },
      ],
    },
    '20241225': {
      name: 'weihnachten1',
      label: {
        de: 'Weihnachten',
        en: 'Christmas Day',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20241226': {
      name: 'weihnachten2',
      label: {
        de: 'Weihnachten',
        en: "St. Stephen's Day",
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
  },
  2025: {
    '20250101': {
      name: 'neujahr',
      label: {
        de: 'Neujahr',
        en: "New Year's Day",
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20250106': {
      name: 'heiligeDreiKoenige',
      label: {
        de: 'Heilige Drei Könige',
        en: 'Epiphany',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
      ],
    },
    '20250308': {
      name: 'internationalerFrauentag',
      label: {
        de: 'Int. Frauentag',
        en: "Int. Women's Day",
      },
      states: [
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
      ],
    },
    '20250418': {
      name: 'karfreitag',
      label: {
        de: 'Karfreitag',
        en: 'Good Friday',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20250420': {
      name: 'ostersonntag',
      label: {
        de: 'Ostersonntag',
        en: 'Easter Sunday',
      },
      states: [
        {
          name: 'Brandenburg',
          short: 'BB',
        },
      ],
    },
    '20250421': {
      name: 'ostermontag',
      label: {
        de: 'Ostermontag',
        en: 'Easter Monday',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20250501': {
      name: 'tagDerArbeit',
      label: {
        de: 'Tag der Arbeit',
        en: 'Labour Day',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20250529': {
      name: 'christiHimmelfahrt',
      label: {
        de: 'Christi Himmelfahrt',
        en: 'Ascension Day',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20250608': {
      name: 'pfingstsonntag',
      label: {
        de: 'Pfingstsonntag',
        en: 'Whit Sunday',
      },
      states: [
        {
          name: 'Brandenburg',
          short: 'BB',
        },
      ],
    },
    '20250609': {
      name: 'pfingstmontag',
      label: {
        de: 'Pfingstmontag',
        en: 'Whit Monday',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20250619': {
      name: 'fronleichnam',
      label: {
        de: 'Fronleichnam',
        en: 'Corpus Christi',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
      ],
    },
    '20250808': {
      name: 'augsburgerFriedensfest',
      label: {
        de: 'Augsburger Friedensfest',
        en: 'Augsburg Peace Festival',
      },
      states: [
        {
          name: 'Bayern',
          short: 'BY',
        },
      ],
    },
    '20250815': {
      name: 'mariaeHimmelfahrt',
      label: {
        de: 'Mariä Himmelfahrt',
        en: 'Assumption of Mary',
      },
      states: [
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
      ],
    },
    '20250920': {
      name: 'weltkindertag',
      label: {
        de: 'Weltkindertag',
        en: "World Children's Day",
      },
      states: [
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20251003': {
      name: 'tagDerDeutschenEinheit',
      label: {
        de: 'Tag der Deutschen Einheit',
        en: 'German Unity Day',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20251031': {
      name: 'reformationstag',
      label: {
        de: 'Reformationstag',
        en: 'Reformation Day',
      },
      states: [
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20251101': {
      name: 'allerheiligen',
      label: {
        de: 'Allerheiligen',
        en: "All Saints' Day",
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
      ],
    },
    '20251119': {
      name: 'bussundBettag',
      label: {
        de: 'Buß und Bettag',
        en: 'Repentance Day',
      },
      states: [
        {
          name: 'Sachsen',
          short: 'SN',
        },
      ],
    },
    '20251225': {
      name: 'weihnachten1',
      label: {
        de: 'Weihnachten',
        en: 'Christmas Day',
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
    '20251226': {
      name: 'weihnachten2',
      label: {
        de: 'Weihnachten',
        en: "St. Stephen's Day",
      },
      states: [
        {
          name: 'Baden-Württemberg',
          short: 'BW',
        },
        {
          name: 'Bayern',
          short: 'BY',
        },
        {
          name: 'Berlin',
          short: 'BE',
        },
        {
          name: 'Bremen',
          short: 'HB',
        },
        {
          name: 'Brandenburg',
          short: 'BB',
        },
        {
          name: 'Hamburg',
          short: 'HH',
        },
        {
          name: 'Hessen',
          short: 'HE',
        },
        {
          name: 'Mecklenburg-Vorpommern',
          short: 'MV',
        },
        {
          name: 'Niedersachsen',
          short: 'NI',
        },
        {
          name: 'Nordrhein-Westfalen',
          short: 'NW',
        },
        {
          name: 'Rheinland-Pfalz',
          short: 'RP',
        },
        {
          name: 'Saarland',
          short: 'SL',
        },
        {
          name: 'Sachsen',
          short: 'SN',
        },
        {
          name: 'Sachsen-Anhalt',
          short: 'ST',
        },
        {
          name: 'Schleswig-Holstein',
          short: 'SH',
        },
        {
          name: 'Thüringen',
          short: 'TH',
        },
      ],
    },
  },
};
