import { DocumentData, DocumentReference, Timestamp, addDoc, collection, doc } from 'firebase/firestore';
import { db } from 'localFirebase';

export interface iLogError {
  timestamp: Timestamp;
  message: string;
  code: string;
  customer: string;
  house?: string;
  userId: string;
  comment?: string;
}

export const getFeaturesRef = (): DocumentReference<DocumentData> => {
  const featuresRef = collection(db, 'info');
  const featuresDoc = doc(featuresRef, 'features');
  return featuresDoc;
};

export const logError = (error: iLogError): void => {
  const errorRef = collection(db, 'errors');
  addDoc(errorRef, error);
};
