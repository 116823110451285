import { checkTenantId, checkUserId } from 'database';
import { iLeasingEmployeeOffer, iLeasingRequest } from 'datamodel';
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Query,
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from 'localFirebase';
import moment, { Moment } from 'moment';

const debug = false;
export const getLeasingOfferById = (id: string): DocumentReference<DocumentData> | false => {
  if (debug) console.log('getLeasingOfferById');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingOffers');
  const docRef = doc(offers, id);
  return docRef;
};

export const getLeasingEmployeeOfferById = (id: string): DocumentReference<DocumentData> | false => {
  if (debug) console.log('leasingEmployeeOffers');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingEmployeeOffers');
  const docRef = doc(offers, id);
  return docRef;
  // try {
  //   const d = await getDoc(docRef);
  //   return { id: d.id, ...d.data() } as iLeasingEmployeeOffer;
  // } catch (error) {
  //   const err = error as FirebaseError;
  //   console.log(err.code);
  //   console.log(err.message);
  //   console.log(err.name);
  //   console.log(err);
  //   console.log(error);
  // }
  // return false;
};

export const getLeasingEmployeeOffersByDate = async (date: string): Promise<iLeasingEmployeeOffer[] | false> => {
  if (debug) console.log('getLeasingEmployeeOfferByDate');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingEmployeeOffers');
  const q = query(offers, where('whenString', '==', date), where('leasingCompanyId', '==', tenantId));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) return false;
  const offersArray = querySnapshot.docs.map(
    (docOffer) => ({ id: docOffer.id, ...docOffer.data() } as iLeasingEmployeeOffer),
  );
  return offersArray;
};

export const getLeasingOffersByDate = async (date: string): Promise<iLeasingRequest[] | false> => {
  if (debug) console.log('getLeasingOfferByDate');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingOffers');
  const q = query(offers, where('whenString', '==', date), where('leasingCompanyIds', 'array-contains', tenantId));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) return false;
  const offersArray = querySnapshot.docs.map(
    (docOffer) => ({ id: docOffer.id, ...docOffer.data() } as iLeasingRequest),
  );
  return offersArray;
};

export const getLeasingEmployeeOffers = (): Query<DocumentData> | false => {
  if (debug) console.log('leasingEmployeeOffers');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingEmployeeOffers');
  const q = query(offers, where('leasingCompanyId', '==', tenantId), orderBy('whenString', 'asc'));
  return q;
};

export const cancelOfferToCustomer = async (offerId: string): Promise<void> => {
  const tenantId = checkTenantId();
  if (!tenantId) return;
  const offerRef = collection(db, 'leasingEmployeeOffers');
  await deleteDoc(doc(offerRef, offerId));
};

export const getOfferedToCustomerForOneEmployee = (employeeId: string): Query<DocumentData> | false => {
  if (debug) console.log('getOfferedToCustomerForOneEmployee');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const requests = collection(db, 'leasingEmployeeOffers');
  const q = query(
    requests,
    where('leasingCompanyId', '==', tenantId),
    where(`employee.id`, '==', employeeId),
    where('whenString', '>=', moment().format('YYYYMMDD')),
    orderBy('whenString', 'asc'),
  );
  return q;
};

export const getLeasingEmployeeOffersRef = (): CollectionReference<DocumentData> | false => {
  if (debug) console.log('getLeasingEmployeeOffersRef');
  const offers = collection(db, 'leasingEmployeeOffers');
  return offers;
};

export const getFirsOfferFromOffersRef = async (
  commonId: string,
  whenString: string,
): Promise<DocumentReference<DocumentData> | false> => {
  if (debug) console.log('getFirsOfferFromOffersRef');
  const offersRef = getLeasingEmployeeOffersRef();
  if (!offersRef) return false;
  if (!commonId || !whenString) return false;
  const q = query(offersRef, where('commonId', '==', commonId), where('whenString', '==', whenString));
  const snap = await getDocs(q);
  if (snap.empty) return false;
  return snap.docs[0].ref;
};

export const updateLeasingEmployeeOffer = async (
  id: string,
  offer: Partial<iLeasingEmployeeOffer>,
): Promise<boolean> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingEmployeeOffers');
  const docRef = doc(offers, id);
  await updateDoc(docRef, {
    ...offer,
    updatedAt: Timestamp.now(),
    updatedBy: checkUserId(),
  });
  return true;
};
export const getOffersForUserByDateRef = (uid: string, startDate: Moment, endDate: Moment): Query | false => {
  if (!uid || !startDate || !endDate) return false;
  const tenantId = checkTenantId();
  const offersRef = collection(db, 'leasingEmployeeOffers');
  if (!offersRef) return false;
  const q = query(
    offersRef,
    where('leasingCompanyId', '==', tenantId),
    where('whenString', '>=', moment(startDate).format('YYYYMMDD')),
    where('whenString', '<=', moment(endDate).format('YYYYMMDD')),
    where('employee.id', '==', uid),
  );
  return q;
};

export const getOffersForCustomerRef = (houseId: string): Query | false => {
  if (!houseId) return false;
  const offersRef = collection(db, 'leasingEmployeeOffers');
  if (!offersRef) return false;
  const q = query(offersRef, where('acceptedById', '==', houseId), orderBy('whenString', 'asc'));
  return q;
};

export const getAcceptedOffersForCustomerByDateRef = (
  houseId: string,
  startDate: Moment,
  endDate: Moment,
): Query | false => {
  if (!houseId || !startDate || !endDate) return false;
  const offersRef = collection(db, 'leasingEmployeeOffers');
  if (!offersRef) return false;
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const q = query(
    offersRef,
    where('acceptedById', '==', houseId),
    where('leasingCompanyId', '==', tenantId),
    where('whenString', '>=', moment(startDate).format('YYYYMMDD')),
    where('whenString', '<=', moment(endDate).format('YYYYMMDD')),
    where('status', '==', 'accepted'),
    orderBy('whenString', 'asc'),
  );
  return q;
};
