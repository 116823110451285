import { theme } from 'shared';

// eslint-disable-next-line import/prefer-default-export
export const defaultColors = {
  primary: theme.colors.products.neutral.primary,
  secondary: theme.colors.products.neutral.secondary,
  accent: theme.colors.products.neutral.accent,
  accentTransparent: theme.colors.products.neutral.accentRgba(0.3),
  primaryTransparent: theme.colors.products.neutral.primaryRgba(0.3),
  primaryTransparentLight: theme.colors.products.neutral.primaryRgba(0.2),
  primaryDark: theme.colors.products.neutral.primaryDark,
  primaryLight: theme.colors.products.neutral.primaryLight,
  primaryText: theme.colors.products.neutral.secondaryFontColor,
  primaryTextSoft: theme.colors.products.neutral.secondaryFontColorRgba(0.8),
  primaryTextRgba: (opacity: number | undefined) => theme.colors.products.neutral.secondaryFontColorRgba(opacity || 1),
  secondaryText: theme.colors.products.neutral.primaryFontColor,
  secondaryTextSoft: theme.colors.products.neutral.primaryFontColorRgba(0.8),
  secondaryTextRgba: (opacity: number | undefined) => theme.colors.products.neutral.primaryFontColorRgba(opacity || 1),
  backgroundRgba: (opacity: number | undefined) => theme.colors.backgroundRgba(opacity || 1),
  accentRgba: (opacity: number | undefined) => theme.colors.products.neutral.accentRgba(opacity || 1),
  primaryRgba: (opacity: number | undefined) => theme.colors.products.neutral.primaryRgba(opacity || 1),
  secondaryRgba: (opacity: number | undefined) => theme.colors.products.neutral.secondaryRgba(opacity || 1),
  surface: theme.colors.surface,
  surfaceDark: theme.colors.surfaceDark,
  surfaceRgba: (opacity: number | undefined) => theme.colors.surfaceRgba(opacity || 1),
  background: theme.colors.white,
  secondaryContainer: 'transparent',
  grey: theme.colors.products.grey,
  disabled: theme.colors.disabled,
  white: theme.colors.white,
  dropShadow: theme.colors.dropShadow,
  border: theme.colors.border,
  lights: theme.colors.lights,
  black: theme.colors.black,
  whiteOverlay: theme.colors.whiteOverlayRgba(0.6),
  primaryError: theme.colors.products.error.primary,
  blackFontLight: theme.colors.blackFont.light,
  blackFontNormal: theme.colors.blackFont.normal,
  blackTransparent: theme.colors.blackRgba(0.1),
};
