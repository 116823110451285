import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { iReleaseNote, iReleaseNoteState } from 'datamodel';

const initialState: iReleaseNoteState[] = [];

export const releaseNotesSlice = createSlice({
  name: 'releaseNotes',
  initialState,
  reducers: {
    setReleaseNotesValue: (state, action: PayloadAction<iReleaseNote[]>) => {
      return action.payload;
    },
    updateReleaseNotesValue: (state, action: PayloadAction<iReleaseNote>) => {
      const index = state.findIndex((d) => d.id === action.payload.id);
      if (index === -1) return state;
      const newState = [...state];
      newState[index] = action.payload;
      return newState;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setReleaseNotesValue, updateReleaseNotesValue, reset } = releaseNotesSlice.actions;

export default releaseNotesSlice.reducer;
