import { checkTenantId } from 'database';
import { iLeasingEmployee } from 'datamodel';
import { CollectionReference, DocumentData, DocumentReference, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from 'localFirebase';

const debug = false;
export const getEmployeesRef = (): CollectionReference<DocumentData> | false => {
  if (debug) console.log('getEmployeesRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const leasingEmployees = collection(db, 'leasingCompanies', tenantId, 'employees');
  return leasingEmployees;
};

export const updateAnEmployee = async (id: string, value: Partial<iLeasingEmployee>): Promise<boolean> => {
  if (debug) console.log('updateAnEmployee');
  const leasingEmployeesRef = getEmployeesRef();
  if (!leasingEmployeesRef) return false;
  const employeeRef = doc(leasingEmployeesRef, id);
  await updateDoc(employeeRef, value);
  return true;
};

export const getAnEmployeeRef = (id: string): DocumentReference<DocumentData> | false => {
  if (debug) console.log('getAnEmployeeRef');
  const leasingEmployeesRef = getEmployeesRef();
  if (!leasingEmployeesRef) return false;
  const employeeRef = doc(leasingEmployeesRef, id);
  return employeeRef;
};

export const getDispatchersRef = (): CollectionReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const dispatchersRef = collection(db, 'leasingCompanies', tenantId, 'users');
  return dispatchersRef;
};
