import { Timestamp } from '@firebase/firestore';
import { iCVPBereich } from '../../Interfaces/ConnextVivendiPep';
import { iDepartment, iDepartmentRef } from '../../Interfaces/Departments';
import { iDeploymentLocation } from '../../Interfaces/Location';
import { iQualification } from '../../Interfaces/Qualifications';
import { iShift } from '../../Interfaces/Shifts';
import { iUserRef } from '../../Interfaces/Users';

export class DepartmentDto implements iDepartment {
  deploymentLocations: iDeploymentLocation[];
  id: string;
  createdAt: Timestamp;
  createdBy: string;
  email: string;
  managers: iUserRef[];
  name: string;
  phoneNumber: string;
  shifts: iShift[];
  qualifications: iQualification[];
  allowRequestsFrom: iDepartmentRef[] | null;
  extId: string | null;
  apiData: iCVPBereich | null;
}

export class CreateDepartmentDto implements iDepartment {
  deploymentLocations: iDeploymentLocation[];
  id: string;
  createdAt: Timestamp;
  createdBy: string;
  email: string;
  managers: iUserRef[];
  name: string;
  phoneNumber: string;
  shifts: iShift[];
  qualifications: iQualification[];
  allowRequestsFrom: iDepartmentRef[] | null;
  extId: string | null;
  apiData: iCVPBereich | null;
}

export class UpdateDepartmentDto extends CreateDepartmentDto {}
