import { Timestamp } from '@firebase/firestore';
import { IsEmpty, IsNotEmpty, IsNumber, IsPositive } from 'class-validator';
import { eShiftType } from '../../Enums';
import { iCVPDienst } from '../../Interfaces/ConnextVivendiPep';
import { TimestampClient } from '../../Interfaces/Offers';
import { iEmoji, iShift } from '../../Interfaces/Shifts';

class CreateShiftFromTillDto {
  @IsNumber()
  @IsPositive()
  h: number;
  @IsNumber()
  @IsPositive()
  m: number;
}

class Emoji implements iEmoji {
  unified: string;
  activeSkinTone: string;
  originalUnified: string;
  names: string[];
  @IsNotEmpty()
  emoji: string;
  @IsNotEmpty()
  name: string;
  @IsNotEmpty()
  unicode: string;
}

export class CreateShiftDto implements iShift {
  type: eShiftType;
  @IsEmpty()
  id: string;
  @IsEmpty()
  createdAt: Timestamp;
  @IsEmpty()
  createdBy: string;
  @IsNotEmpty()
  from: CreateShiftFromTillDto;
  @IsEmpty()
  fromTill: string;
  @IsNotEmpty()
  name: string;
  @IsNotEmpty()
  short: string;
  icon: Emoji | null;
  till: CreateShiftFromTillDto;
  @IsNumber()
  @IsPositive()
  breakTime?: number | undefined;
  @IsNumber()
  @IsPositive()
  workTime?: number | undefined;
  @IsEmpty()
  updatedAt: Timestamp;
  @IsEmpty()
  updatedBy: string;
  @IsEmpty()
  poolShiftId: string;
  apiData: iCVPDienst | null;
  extId: string | null;
}

class ShiftFromTillDto {
  @IsNumber()
  @IsPositive()
  h: number;
  @IsNumber()
  @IsPositive()
  m: number;
}

export class ShiftDto implements iShift {
  type: eShiftType;
  @IsEmpty()
  id: string;
  @IsEmpty()
  createdAt: TimestampClient;
  @IsEmpty()
  createdBy: string;
  @IsNotEmpty()
  from: ShiftFromTillDto;
  @IsEmpty()
  fromTill: string;
  @IsNotEmpty()
  name: string;
  @IsNotEmpty()
  short: string;
  icon: Emoji | null;
  till: ShiftFromTillDto;
  @IsNumber()
  @IsPositive()
  breakTime?: number;
  @IsNumber()
  @IsPositive()
  workTime?: number;
  @IsEmpty()
  updatedAt: TimestampClient;
  @IsEmpty()
  updatedBy: string;

  @IsEmpty()
  poolShiftId: string;

  apiData: iCVPDienst | null;
  extId: string | null;
}

export class UpdateShiftDto extends CreateShiftDto {}
