import { IsEmpty, IsNotEmpty, IsString } from 'class-validator';
import { iCVPQualifikation } from '../../Interfaces/ConnextVivendiPep';
import { iQualification } from '../../Interfaces/Qualifications';

export class QualificationDto implements iQualification {
  @IsEmpty()
  id?: string;
  @IsNotEmpty()
  @IsString()
  shortCode: string;
  @IsNotEmpty()
  @IsString()
  name: string;
  apiData: iCVPQualifikation | null;
  extId: string | null;
}

export class CreateQualificationDto implements iQualification {
  @IsEmpty()
  id?: string;
  @IsNotEmpty()
  @IsString()
  shortCode: string;
  @IsNotEmpty()
  @IsString()
  name: string;

  extId: string | null;
  apiData: iCVPQualifikation | null;
}

export class UpdateQualificationDto extends CreateQualificationDto {}
