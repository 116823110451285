import { Timestamp } from '@firebase/firestore';
import {
  ArrayMinSize,
  IsArray,
  IsBoolean,
  IsDefined,
  IsNotEmpty,
  IsNumberString,
  IsOptional,
  IsString,
  MaxLength,
  MinLength,
  ValidateIf,
} from 'class-validator';
import { eOfferTypes } from '../../Enums';
import { iDepartment } from '../../Interfaces/Departments';
import { iCompensationType } from '../../Interfaces/House';
import {
  iOffer,
  iOfferAccepted,
  iOfferAssigned,
  iOfferExternal,
  iOfferRejected,
  iSendToWhom,
} from '../../Interfaces/Offers';
import { iQualification } from '../../Interfaces/Qualifications';
import { iShift } from '../../Interfaces/Shifts';
import { DepartmentDto } from '../departments';
import { ShiftDto } from '../shifts';

export class CreateOfferDto implements iOffer {
  cancelReason?: string | undefined;
  deploymentLocationId?: string | undefined;
  relatedOfferIds?: string[] | undefined;
  isDeploymentLocationOffer: boolean;
  groupId: string;
  atDepartment: iDepartment;
  shift: iShift;
  external: false | iOfferExternal;
  type: eOfferTypes.Offer;
  accepted?: { [key: string]: iOfferAccepted } | undefined;
  rejected?: { [key: string]: iOfferRejected } | undefined;
  assigned?: iOfferAssigned | undefined;
  availableCompensations: iCompensationType[];
  note: string | undefined;
  id: string;
  isNew: boolean;
  isSent: boolean;
  isAssigned: boolean;
  qualifications: iQualification[];
  sendToWhom: iSendToWhom;
  whenString: string;
  createdBy: { id: string; displayName: string };
  createdAt: Timestamp;
  assignedBy?: { id: string; displayName: string } | undefined;
  assignedAt?: Timestamp | undefined;
  cancelled: boolean;
  apiData: null;
}

export class CreateOfferFromRequestDto {
  @IsNotEmpty()
  @IsString()
  atDepartment: string; // The ID of the department
  @IsNotEmpty()
  @IsString()
  shift: string; // The ID of the shift
  @IsArray()
  @IsString({ each: true })
  compensation: string[]; // The IDs of the compensations
  @IsString()
  note: string;
  @IsArray()
  @IsString({ each: true })
  @ArrayMinSize(1)
  qualifications: string[]; // The IDs of the qualifications
  @IsNumberString({ no_symbols: true })
  @MinLength(8)
  @MaxLength(8)
  when: string;
  @IsOptional()
  @IsBoolean()
  sendToAllDepartments?: boolean;
  @ValidateIf((o) => !o.sendToAllDepartments)
  @IsOptional()
  @IsBoolean()
  sendToOwnDepartment: boolean;
  @ValidateIf((o) => !o.sendToOwnDepartment)
  @IsArray()
  @IsString({ each: true })
  @ArrayMinSize(1)
  sendToDepartments: string[]; // The IDs of the departments
  @ValidateIf(
    (o) =>
      (o.sendToAllDepartments && o.sendToOwnDepartment) ||
      (o.sendToAllDepartments && o.sendToDepartments) ||
      (o.sendToOwnDepartment && o.sendToDepartments),
  )
  @IsDefined({
    message:
      'Only one of the following options can be set: sendToOwnDepartment, sendToAllDepartments or sendToDepartments.',
  })
  protected readonly checkOnlyOne: undefined;
}

export class OfferDto implements iOffer {
  cancelReason?: string | undefined;
  deploymentLocationId?: string | undefined;
  relatedOfferIds?: string[] | undefined;
  isDeploymentLocationOffer: boolean;
  groupId: string;
  atDepartment: DepartmentDto;
  shift: ShiftDto;
  external: false | iOfferExternal;
  type: eOfferTypes.Offer;
  accepted?: { [key: string]: iOfferAccepted } | undefined;
  rejected?: { [key: string]: iOfferRejected } | undefined;
  assigned?: iOfferAssigned | undefined;
  availableCompensations: iCompensationType[];
  note: string | undefined;
  id: string;
  isNew: boolean;
  isSent: boolean;
  isAssigned: boolean;
  qualifications: iQualification[];
  sendToWhom: iSendToWhom;
  whenString: string;
  createdBy: { id: string; displayName: string };
  createdAt: Timestamp;
  assignedBy?: { id: string; displayName: string } | undefined;
  assignedAt?: Timestamp | undefined;
  cancelled: boolean;
  apiData: null;
}

export class UpdateOfferDto extends CreateOfferDto {}
