const USERNAME_MIN_LENGTH = 6;

export const checkIfUsernameIsValid = (username: string) => {
  const containsDot = username.includes('.');
  const containsAtSymbol = username.includes('@');

  // regex for checking if username contains only letters and numbers and _ and - and .
  const usernameRegex = /^[a-zA-Z0-9_.-]*$/;
  if (!usernameRegex.test(username)) {
    return false;
  }

  if (username.length < USERNAME_MIN_LENGTH) {
    return false;
  }
  if (!containsDot || containsAtSymbol) {
    return false;
  }
  return true;
};

export const checkIfEmailIsValid = (email: string) => {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
};

export const checkIfPasswordIsValid = (password: string) => {
  const minLength = 6;
  if (password.length < minLength) {
    return false;
  }
  return true;
};

export const checkIfLoginNameIsValid = (loginName: string) => {
  if (!loginName.includes('@')) {
    return checkIfUsernameIsValid(loginName);
  }
  return checkIfEmailIsValid(loginName);
};

export const checkIfLoginDataIsValid = (userName: string, password: string) => {
  if (!userName.includes('@')) {
    return checkIfUsernameIsValid(userName) && checkIfPasswordIsValid(password);
  }
  return checkIfEmailIsValid(userName) && checkIfPasswordIsValid(password);
};
